import React from 'react';
import {
    Field,
    reduxForm,
} from 'redux-form';
import { Alert, Modal, Spinner } from 'react-bootstrap';
import { withRouter } from 'react-router';

import { Show } from 'Layout';
import { PageStatus } from 'enums';
import {AuthAPI, SecAPI} from "../../API";
import {ProfileManagementAPI} from "../../API/ProfileManagementAPI";
import { changePasswordDict } from 'Languages/ContactsTranslations';
import { checkNumberValidation, checkStrongPassword } from "../../Helper/Validations";

export type FormValue = {
    "name": string,
};

type State = {
    status: string,
    error: string | null,
    country: any,
    name: string,
};

class ChangePasswordForm extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            status: PageStatus.None,
            error: null,
            currentPassword: '',
            newPassword: "",
            confirmPassword: '',
            isPasswordMatched: false,
            isValidPassword: true,
            notValidPasswordText: "",
            isValidConfirmPassword: true,
            notValidConfirmPasswordText: "",
            isConfirmPasswordVisible: false,
            isNewPasswordVisible: false,
            isCurrentPasswordVisible: false,
            
        };
    }

    formValues() {
        return {
            currentPassword: this.state.currentPassword,
            newPassword: this.state.newPassword,
            userId: this.props.id,
        };
    }

    onSubmit() {
        if (this.props.id) {
            return this.ChangePassword();
        }
    }

    ChangePassword() {
        const valuesIn = this.formValues()
        return Promise.resolve()
            .then(() => this.setState({ status: PageStatus.Submitting }))
            .then(() => AuthAPI.ChangePassword(valuesIn))
            .then((country) => {
                this.props.onSubmit();
                alert(this.props.language === 'hi' ? 'पासवर्ड सफलतापूर्वक बदला गया' : 'Password Changed Successfully.');
                return this.setState({ status: PageStatus.Submitted });
            })
            .catch((error) => {
                this.setState({ status: PageStatus.Error, error: (this.props.language === 'hi' ? 'पुराना पासवर्ड गलत है।' : error.message) });
            });
    }

    isPasswordStrong(ele, type){ 
        this.setState({error: null,});
        if(ele.target.value != ""){
          let passwordStorg = checkStrongPassword(ele);
          let isHindiTrue = this.props.language === 'hi' ? true : false;
          let myText = "";
          passwordStorg.lengthCheck == true ? "" : myText = `${type == 'password' ? ( isHindiTrue ? "पासवर्ड" :'Password') : (isHindiTrue ? "कन्फर्म पासवर्ड":'Confirm password')} ${isHindiTrue ? " की लंबाई कम से कम 8 अक्षर की होनी चाहिए" : " length should be atleast of 8 character"}`; 
          passwordStorg.capitalLatterCheck == true ? "" : myText = `${type == 'password' ? ( isHindiTrue ? "पासवर्ड" :'Password') : (isHindiTrue ? "कन्फर्म पासवर्ड":'Confirm password')} ${isHindiTrue ? " में एक बड़ा अक्षर होना चाहिए" : " should contain one capital letter"}`; 
          passwordStorg.smallLatterCheck == true ? "" : myText = `${type == 'password' ? ( isHindiTrue ? "पासवर्ड" :'Password') : (isHindiTrue ? "कन्फर्म पासवर्ड":'Confirm password')} ${isHindiTrue ? " में एक छोटा अक्षर होना चाहिए" : " should contain one small letter"}`; 
          passwordStorg.numericValCheck == true ? "" : myText = `${type == 'password' ? ( isHindiTrue ? "पासवर्ड" :'Password') : (isHindiTrue ? "कन्फर्म पासवर्ड":'Confirm password')} ${isHindiTrue ? " में एक संख्यात्मक मान होना चाहिए" : " should contain one numeric value"}`; 
          passwordStorg.specialCharCheck == true ? "" : myText = `${type == 'password' ? ( isHindiTrue ? "पासवर्ड" :'Password') : (isHindiTrue ? "कन्फर्म पासवर्ड":'Confirm password')} ${isHindiTrue ? " में एक विशेष प्रतीक होना चाहिए" : " should contain one special character"}`; 
          if(passwordStorg.capitalLatterCheck == true && passwordStorg.smallLatterCheck == true && passwordStorg.numericValCheck == true && passwordStorg.specialCharCheck == true && passwordStorg.lengthCheck == true && type == "password"){
            this.setState({ isValidPassword: true, notValidPasswordText: "", isButtonDisabled: false, })
          }else if(passwordStorg.capitalLatterCheck == true && passwordStorg.smallLatterCheck == true && passwordStorg.numericValCheck == true && passwordStorg.specialCharCheck == true && passwordStorg.lengthCheck == true && type == "confirmpassword"){
            this.setState({ isValidConfirmPassword: true, notValidConfirmPasswordText: "", isButtonDisabled: false, })
          }else{ 
            if(type == "password"){
              this.setState({ isValidPassword: false, notValidPasswordText: myText, isButtonDisabled: true, })
            }else{
              this.setState({ isValidConfirmPassword: false, notValidConfirmPasswordText: myText, isButtonDisabled: true, })
            }
          }
        }else{ 
          if(type == "password"){
            this.setState({ isValidPassword: true, notValidPasswordText: "", isButtonDisabled: false, })
          }else{
            this.setState({ isValidConfirmPassword: true, notValidConfirmPasswordText: "", isButtonDisabled: false, })
          }
        }
    }

    onChangePassword(value, type){
        let { newPassword, confirmPassword } = this.state;
        if(type === 'confirmPassword'){
            confirmPassword = value;
        }else{
            newPassword = value;
        }
        if(newPassword !== '' && confirmPassword !== '' && newPassword === confirmPassword){
            this.setState({
                isPasswordMatched : true
            })
        }else{
            this.setState({
                isPasswordMatched : false
            })
        }
    }

    reset() {
        return this.setState({
            currentPassword: '',
            newPassword: '',
            confirmPassword: ''
        });
    }

    render() {
        const lang = this.props.language ?? 'en';
        return (
            <Modal
            centered
            size="lg"
            backdrop="static"
            onHide={this.props.onClose}
            show={this.props.show}
            style={{ zIndex: 1201 }}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {changePasswordDict[lang]["Change Password"] || "Change Password"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ maxHeight: '78vh', overflow: 'auto' }}>
                <Show when={this.state.status === PageStatus.Loading}>
                    <div className="d-flex justify-content-center w-100 p-5">
                        <Spinner animation="border" variant="primary" />
                    </div>
                </Show>

                <form onSubmit={this.props.handleSubmit(() => this.onSubmit())}>
                    <div className="form-group">

                        <label htmlFor="titleEng">
                            {changePasswordDict[lang]["Current Password"] || "Current Password"}*
                        </label>
                        <div  className="password-container" style={{ position: 'relative',}}>
                            <button
                            type="button"
                            style={{ position: 'absolute', top: '70%', backgroundColor:"white", right: '10px', transform: 'translateY(30%)' }}
                            onClick={() => this.setState({ isCurrentPasswordVisible: !this.state.isCurrentPasswordVisible })}
                            >
                            <i className={!this.state.isCurrentPasswordVisible ? "fa fa-eye-slash bg-none" : "fa fa-eye"}></i>
                            </button>
                        </div>
                        <input
                            // type='password'
                            type={this.state.isCurrentPasswordVisible ? 'text' : 'password'}
                            className="form-control"
                            onChange={(e) => this.setState({ currentPassword: e.target.value, error: null })}
                            value={this.state.currentPassword}
                            placeholder={changePasswordDict[lang]["Enter..."] || "Enter..."}
                            required
                        />


                        <label htmlFor="titleEng">
                            {changePasswordDict[lang]["New Password"] || "New Password"}*
                        </label>
                        <div  className="password-container" style={{ position: 'relative',}}>
                            <input
                                className="form-control"
                                type={this.state.isNewPasswordVisible ? 'text' : 'password'}
                                onChange={(e) =>{ 
                                    this.onChangePassword(e.target.value, 'newPassword');
                                    this.setState({ newPassword : e.target.value });
                                }}
                                onInput={(e)=>this.isPasswordStrong(e, 'password')}
                                value={this.state.newPassword}
                                placeholder={changePasswordDict[lang]["Enter..."] || "Enter..."}
                                required
                            />
                            <button
                                type="button"
                                style={{ position: 'absolute', top: '50%', backgroundColor:"white", right: '10px', transform: 'translateY(-50%)' }}
                                onClick={() => this.setState({ isNewPasswordVisible: !this.state.isNewPasswordVisible })}
                                >
                                <i className={!this.state.isNewPasswordVisible ? "fa fa-eye-slash bg-none" : "fa fa-eye"}></i>
                            </button>
                        </div>
                        {this.state.isValidPassword != true ? <div className="text-danger mt-2" style={{fontSize:`${window.matchMedia("(max-width: 768px)").matches ? "18px" : "16px"}`}}>{this.state.notValidPasswordText}</div> : "" }

                        <label htmlFor="titleEng">
                            {changePasswordDict[lang]["Confirm New Password"] || "Confirm New Password"}*
                        </label>
                        <div className="password-container" style={{ position: 'relative',}}>
                            <input
                                type={this.state.isConfirmPasswordVisible ? 'text' : 'password'}
                                className="form-control"
                                onChange={(e) => {
                                    this.onChangePassword(e.target.value, 'confirmPassword');
                                    this.setState({ confirmPassword : e.target.value });
                                }}
                                onInput={(e)=>this.isPasswordStrong(e, 'confirmpassword')}
                                value={this.state.confirmPassword}
                                placeholder={changePasswordDict[lang]["Enter..."] || "Enter..."}
                                required
                            />
                            <button
                                type="button"
                                style={{ position: 'absolute', top: '50%', backgroundColor:"white", right: '10px', transform: 'translateY(-50%)' }}
                                onClick={() => this.setState({ isConfirmPasswordVisible: !this.state.isConfirmPasswordVisible })}
                                >
                                <i className={!this.state.isConfirmPasswordVisible ? "fa fa-eye-slash bg-none" : "fa fa-eye"}></i>
                            </button>
                        </div>
                        {this.state.isValidConfirmPassword != true ? <div className="text-danger mt-2"  style={{fontSize:`${window.matchMedia("(max-width: 768px)").matches ? "18px" : "16px"}`}} >{this.state.notValidConfirmPasswordText}</div> : "" }
                    </div>

                    {this.state.isPasswordMatched === true && this.state.isValidPassword && this.state.isValidConfirmPassword && !this.state.error ?
                        <div className="alert alert-success">{changePasswordDict[lang]["Passwords Matched"] || "Passwords matched!"}</div> : ""
                    }
                    {this.state.isPasswordMatched !== true && this.state.newPassword && this.state.confirmPassword && this.state.isValidPassword && this.state.isValidConfirmPassword ?
                        <div className="alert alert-danger">{changePasswordDict[lang]["Not Matched"] || "Not Matched"}</div> : ""
                    }
                    <hr />
                    <Alert variant="danger" show={!!this.state.error} className="mt-2">
                        {this.state.error}
                    </Alert>

                    <div className="d-flex align-items-center mt-2">
                        <button
                            type="submit"
                            disabled={!this.state.newPassword || !this.state.currentPassword || this.state.newPassword !== this.state.confirmPassword || !this.state.isValidPassword || !this.state.isValidConfirmPassword}
                            className="btn btn-primary mr-3"
                        >
                            {changePasswordDict[lang]["Change"] || "Change"}
                        </button>
                        <Show when={this.state.status === PageStatus.Submitting}>
                            <Spinner animation="border" variant="primary" />
                        </Show>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
        );
    }
}

const ChangePasswordFormRedux = reduxForm<FormValue, any>({
    form: 'labelsChangePasswordForm',
})(ChangePasswordForm);


const ChangePasswordFormWithRouter = withRouter(ChangePasswordFormRedux);

export { ChangePasswordFormWithRouter as ChangePasswordForm };
