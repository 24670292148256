import React from 'react';
import {
  Field,
  reduxForm,
} from 'redux-form';
import { Alert, Modal, Spinner } from 'react-bootstrap';
import { withRouter } from 'react-router';
import { Show } from 'Layout';
import { PageStatus } from 'enums';
import { NewsLetterAPI, SamplesAPI } from "../../API";
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import moment from "moment/moment";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import csvtojson from "csvtojson";
import { number } from 'prop-types';


export type FormValue = {
  name: string,
};


type State = {
  status: string,
  error: string | null,
  data: any,
  name: string,
};

class Form extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      status: PageStatus.None,
      samples: '',
      error: null,
      data: '',
      name: "",
      subject: "",
      "sendDate": "",
      "newsletterStatus": 'inProgress',
      "body": '<div class="ta-scroll-window ng-scope ta-text ta-editor form-control" ng-hide="showHtml"><div class="popover fade bottom" style="max-width: none; width: 305px;"><div class="arrow"></div><div class="popover-content"></div></div><div class="ta-resizer-handle-overlay"><div class="ta-resizer-handle-background"></div><div class="ta-resizer-handle-corner ta-resizer-handle-corner-tl"></div><div class="ta-resizer-handle-corner ta-resizer-handle-corner-tr"></div><div class="ta-resizer-handle-corner ta-resizer-handle-corner-bl"></div><div class="ta-resizer-handle-corner ta-resizer-handle-corner-br"></div><div class="ta-resizer-handle-info"></div></div><div id="taTextElement9972333766307336" contenteditable="true" ta-bind="ta-bind" ng-model="html" class="ng-pristine ng-valid ta-bind">\n' +
        '\n' +
        '\n' +
        '    <meta http-equiv="Content-Type" content="text/html; charset=utf-8">\n' +
        '    <title>IndiaPolls Doc</title> <br />\n' +
        '\n' +
        '\n' +
        '    <link href="https://fonts.googleapis.com/css?family=Open+Sans:400,300,700" rel="stylesheet" type="text/css">\n' +
        '\n' +
        '\n' +
        '\n' +
        '\n' +
        '\n' +
        '\n' +
        '\n' +
        '    <table width="600" border="0" style="margin:0 auto;">\n' +
        '        <tbody><tr>\n' +
        '            <td>\n' +
        '\n' +
        '                <div class="wrapper" style="box-shadow:0px 2px 2px 2px #F3F3F3; o-box-shadow:0px 2px 2px 2px #F3F3F3; moz-box-shadow:0px 2px 2px 2px #F3F3F3;\n' +
        '\twebkit-box-shadow:0px 2px 2px 2px #F3F3F3; min-height:100%; overflow:hidden;  border-left:1px solid #ddd !important; border-right:1px solid #ddd !important; max-width:600px; margin:0 auto; padding:0px !important;">\n' +
        '\n' +
        '                    <div class="" style="padding-left:0px !important; padding-right:0px !important;">\n' +
        '                        <img src="https://panel.indiapolls.com/assets/img/logo-black.png" class="img-responsive center-block">\n' +
        '                    </div>\n' +
        '\n' +
        '\n' +
        '                    <div id="text-area">\n' +
        '                        <div class="">\n' +
        '                            <br>\n' +
        '                            <br>\n' +
        '\n' +
        '                            <p style="padding-left:45px; padding-right:45px; padding-top:15px;\n' +
        'font-size:18px; line-height:32px; font-family: \'Open Sans\', sans-serif;">\n' +
        '                                Dear Panelist ,</p><div class="clearfix"></div>\n' +
        '                            <p></p>\n' +
        '\n' +
        '\n' +
        '\n' +
        '\n' +
        '\n' +
        '\n' +
        '                            <p style="padding-left:45px; padding-right:45px; padding-top:15px;\n' +
        'font-size:18px; line-height:32px; font-family: \'Open Sans\', sans-serif;">\n' +
        '\n' +
        '                                Thank You for choosing IndiaPolls to voice your valuable opinion!<br><br>Congratulations! You have redeemed yourself cash as part of IndiaPolls rewards redemption benefits for successful referrals/surveys that you have completed.<br><br>IndiaPolls is India’s premium survey portal – where we invite registered users to take part in product / brand surveys and earn attractive reward points for their opinions.<br><br>Also, remember that there are more prizes to be won regularly. All you need to do is fill in the surveys that we will send out to you from time to time. And that gives you a chance to earn rewards from Rs. 20 to Rs. 2000 and enter into monthly sweepstakes. You can either REDEEM your points or donate them to a charitable organization of your choice.<br><br>Don’t let this be a secret…talk about us. If you successfully bring a person a friend on-board, you also earn 20 IndiaPolls points whenever a person referred by you becomes a member.<br><br>All the surveys that you respond to are used by various companies to provide better products or services to customers - so your opinion will count!<br><br>We hope that this will be the beginning of a lasting and a fruitful relationship.\n' +
        '                                                        </p>\n' +
        '                            <br><br>\n' +
        '\n' +
        '                            <h3 class="sign" style="font-size:22px; font-weight:900; padding-left:45px;\n' +
        '\tpadding-top:25px; margin-bottom:60px; font-family: \'Open Sans\', sans-serif;">\n' +
        '                                <b>Warm Regards,<br>\n' +
        '                                IndiaPolls Online Panel</b>\n' +
        '                            </h3>\n' +
        '\n' +
        '\n' +
        '                            <div class="panel-footer" style="font-family: \'Open Sans\', sans-serif; margin-left:10px;">\n' +
        '                                <div class="col-md-6" style="width:280px; float:left;">\n' +
        '                                    <img src="https://panel.indiapolls.com/assets/img/logo-black.png" class="img-responsive" style="margin-top:90px !important;">\n' +
        '                                </div>\n' +
        '\n' +
        '\n' +
        '                                    </div>\n' +
        '\n' +
        '                                </div>\n' +
        '\n' +
        '                            </div>\n' +
        '\n' +
        '                        </div>\n' +
        '\n' +
        '                    </div>\n' +
        '\n' +
        '\n' +
        '                </div>\n' +
        '\n' +
        '            </td>\n' +
        '        </tr>\n' +
        '    </tbody></table>\n' +
        '\n' +
        '\n' +
        '\n' +
        '\n' +
        '\n' +
        '</div></div>',
      emails: [],
      phoneNumbers: [],
      userIds: [],
      createdById: '',
      selectedSampleOption: '',
      bulkEmailSheet: '',
      // bulkEmailSheet: '',
      bulkPhoneNumbersSheet: '',
      bulkuserIdsSheet: '',
      disableEmails: false,
      disablePhoneNumbers: true, // Phone number inputs disabled by default
      disableUserIds: true, // User ID inputs disabled by default
      allTempletes: [],
      selectedTempleteDescription: '',
      showHideSelectTempField: '',
      notificationType: 'email', // Default to 'email'
      disableBulkEmailSheet: false, // Bulk email upload enabled by default
      disablebulkPhoneNumbersSheet: true, // Bulk phone number upload disabled by default
      disablebulkuserIdsSheet: true, // Bulk user ID upload disabled by default
      tempId: '',
      cat: '',
      showHideCustomSelectBox: false,
    };
    this.selectRef = React.createRef();
    this.optionsRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside);
    if (!!this.props.id) {
      this.fetchDetails();
    } else {
      this.getAllTempletesFunc({"type":"normal"});
      this.fetchSampleList();
    }
  }

  fetchDetails() {
    Promise.resolve()
      .then(() => this.setState({ status: PageStatus.Loading }))
      .then(() => {
        if (!this.props.id) {
          return Promise.reject(new Error('Invalid ID'));
        }

        return NewsLetterAPI.getOne(this.props.id);
      })
      .then((data) => {
        this.initializeValues(data);
        this.setState({
          data,
          status: PageStatus.Loaded,
        }, () => {
          this.fetchSampleList()
        });
      })
      .catch((error) => {
        this.setState({ status: PageStatus.Error, error: error.message });
      });
  }

  fetchSampleList(): Promise<void> {
    return Promise.resolve()
      .then(() => this.setState({ status: PageStatus.Loading }))
      .then(() => SamplesAPI.getAll("All", 1, {}))
      .then((samplesList: any) => {
        const options = samplesList.data.map(sample => ({
          label: sample.name,
          value: sample.id
        }));
        if (this.state.sample_id) {
          const option = options.find(item => item.value === this.state.sample_id);
          this.setState({ selectedSampleOption: option });
        }
        this.setState({ samples: options, status: PageStatus.Loaded });
      })
      .catch((error) => {
        this.setState({ error: error.message, status: PageStatus.Error });
      });
  }

  formValues() {
    return {
      name: this.state.name,
      subject: this.state.subject,
      "sendDate": this.state.sendDate,
      "newsletterStatus": this.state.newsletterStatus,
      "body": this.state.body,
      "emails": this.state.emails,
      "phoneNumbers": this.state.phoneNumbers,
      "userIds": this.state.userIds,
      createdById: this.props.userId,
      bulkEmailSheet: this.state.bulkEmailSheet,
      bulkPhoneNumbersSheet: this.state.bulkPhoneNumbersSheet,
      bulkuserIdsSheet: this.state.bulkuserIdsSheet,
      disablebulkuserIdsSheet: this.state.disablebulkuserIdsSheet,
      notificationType: this.state.notificationType,
      tempId: this.state.tempId,
      cat: this.state.cat,
    };
  }

  initializeValues(data) {
    return this.setState({
      name: data.name,
      subject: data.subject,
      "sendDate": moment(data.sendDate).format("YYYY-MM-DD HH:mm").replace(" ","T"),
      "newsletterStatus": data.newsletterStatus,
      "body": data.body,
      "emails": data.emails,
      createdById: this.props.userId,
      // showHideSelectTempField: 'd-none',
      sample_id: data.newsletterSample ? data.newsletterSample.sample_id : null,
      tempId: data.tempId,
      cat: data.cat,
      notificationType: data.nofiType,
      disableEmails: data.nofiType !== 'email',
      disablePhoneNumbers: data.nofiType !== 'phoneNumber' && data.nofiType !== 'sms' && data.nofiType !== 'whatsapp',
      disableUserIds: data.nofiType !== 'userId',
      disableBulkEmailSheet: data.nofiType !== 'email',
      disablebulkPhoneNumbersSheet: data.nofiType !== 'phoneNumber' && data.nofiType !== 'sms' && data.nofiType !== 'whatsapp',
      disablebulkuserIdsSheet: data.nofiType !== 'userId',
    },()=>this.getAllTempletesFunc({"type" : data.nofiType == "email" || data.nofiType == "userId" ? "normal" : data.nofiType}));
  }
  onSubmit() {
    if (!this.props.id) {
      return this.create();
    }
    return this.update();
  }

  create() {
    const valuesIn = this.formValues();

    // Check if a bulk file is uploaded for emails, phone numbers, or user IDs
    const hasBulkFile = this.state.bulkEmailSheet || this.state.bulkPhoneNumbersSheet || this.state.bulkuserIdsSheet;
    const isSingleEmailTrue = this.state.emails.length > 0;
    const isSinglePhoneTrue = this.state.phoneNumbers.length > 0;
    const isSingleUserIdTrue = this.state.userIds.length > 0;
    const hasSingleValues = isSingleEmailTrue || isSinglePhoneTrue || isSingleUserIdTrue;
    if(hasBulkFile == "" && !hasSingleValues ){
      alert(`Please select values according to ${this.state.notificationType}`);
      return;
    }

    // Determine which API function to call based on the presence of a bulk file
    const apiRespo = hasBulkFile ? NewsLetterAPI.bulkcreate(valuesIn) : NewsLetterAPI.create(valuesIn);

    return Promise.resolve()
      .then(() => this.setState({ status: PageStatus.Submitting }))
      .then(() => apiRespo)
      .then((data) => {
        this.props.onSubmit(data.id);
        this.fetchDetails();
        return this.setState({ status: PageStatus.Submitted });
      })
      .catch((error) => {
        this.setState({ status: PageStatus.Error, error: error.message });
      });
  }

  // create() {
  //   const valuesIn = this.formValues();

  //   console.log(this.state.emails.length, "this.state.emails.length");
  //   const apiRespo = Number(this.state.emails.length) > 0 ? NewsLetterAPI.create(valuesIn) : NewsLetterAPI.bulkcreate(valuesIn);

  //   return Promise.resolve()
  //     .then(() => this.setState({ status: PageStatus.Submitting }))
  //     .then(() => apiRespo)
  //     .then((data) => {
  //       this.props.onSubmit(data.id);
  //       this.fetchDetails();
  //       return this.setState({ status: PageStatus.Submitted });
  //     })
  //     .catch((error) => {
  //       this.setState({ status: PageStatus.Error, error: error.message });
  //     });
  // }

  update() {
    const valuesIn = this.formValues();
    return Promise.resolve()
      .then(() => this.setState({ status: PageStatus.Submitting }))
      .then(() => NewsLetterAPI.update(this.props.id, valuesIn))
      .then(() => {
        if (this.state.sample_id) {
          NewsLetterAPI.createSample({ sample_id: this.state.sample_id, newsletter_id: this.props.id })
        }
        this.setState({ status: PageStatus.Submitted });
        return this.props.onSubmit(this.props.id);
      })
      .catch((error) => {
        this.setState({ status: PageStatus.Error, error: error.message });
      });
  }

  reset() {
    return this.setState({
      name: null
    });
  }


  handleCreateOption = (inputValue, type) => {
    const newOption = { value: inputValue.trim(), label: inputValue.trim(), type };
    if(type == "emails"){
      this.setState(prevState => ({
        [type]: [...prevState[type], newOption],
      }));
    }else{
      this.setState(prevState => ({
        [type]: [newOption],
      }));
    }
  };

  handleChange = (selectedOptions, type) => {
    if (selectedOptions) {
      this.setState({ [type]: selectedOptions, [`${type}Sheet`]: '', [`disable${type}Sheet`]: true });
    } else {
      this.setState({ [type]: [], [`${type}Sheet`]: '', [`disable${type}Sheet`]: false });
    }
  };


  handleFileChange = async (event) => {

    const file = event.target.files[0];
    const inputName = event.target.name; // Get the input field's name attribute
    const type = inputName; // Extract type by removing 'bulk' from input name

    if (file) {
      try {
        const jsonData = await this.convertCsvToJson(file);
        const transformedData = jsonData.map((obj) => this.modifiedConvertedJson(obj, type));

        // Clear other states when new file is uploaded
        const newState = {
          bulkEmailFile: type === 'bulkEmail' ? file : this.state.bulkEmailFile,
          bulkPhoneNumbersFile: type === 'bulkPhoneNumbers' ? file : this.state.bulkPhoneNumbersFile,
          bulkUserIdsFile: type === 'bulkuserIds' ? file : this.state.bulkUserIdsFile,
          emails: [],
          phoneNumbers: [],
          userIds: [],
          disableEmails: true,
          disablePhoneNumbers: true,
          disableUserIds: true,
          [`${inputName}Sheet`]: transformedData,
          [`disable${type.charAt(0).toUpperCase() + type.slice(1)}`]: false, // Dynamically enable input based on the type
        };

        this.setState(newState);
      } catch (error) {
        console.error('Error converting CSV to JSON:', error);
      }
    } else {
      // Reset state if no file is provided
      this.setState({
        disableEmails: type === 'bulkEmail' ? false : true,
        disablePhoneNumbers: type === 'bulkPhoneNumbers' ? false : true,
        disableUserIds: type === 'bulkuserIds' ? false : true,

      });
    }
  };


  // handleFileChange = async (event) => {
  //   const file = event.target.files[0];
  //   const inputName = event.target.name; // Get the input field's name attribute
  //   const type = inputName; // Extract type by removing 'bulk' from input name
  //   console.log(type, "type inpout");


  //   if (file) {
  //     try {
  //       const jsonData = await this.convertCsvToJson(file);
  //       const transformedData = jsonData.map((obj) => this.modifiedConvertedJson(obj, type));
  //       this.setState({

  //         emails: [],
  //         phoneNumbers: [],
  //         userIds: [],
  //         disableEmails: type === 'bulkEmail' ? false : true,
  //         disablePhoneNumbers: type === 'bulkPhoneNumbers' ? false : true,
  //         disableUserIds: type === 'bulkUserIds' ? false : true,
  //         [`${type}Sheet`]: transformedData,
  //         [`disable${type.charAt(0).toUpperCase() + type.slice(1)}`]: true // Dynamically disable the input based on the type
  //       });
  //     } catch (error) {
  //       console.error('Error converting CSV to JSON:', error);
  //     }
  //   } else {
  //     this.setState({
  //       disableEmails: type === 'bulkEmail' ? false : true,
  //       disablePhoneNumbers: type === 'bulkPhoneNumbers' ? false : true,
  //       disableUserIds: type === 'bulkUserIds' ? false : true,
  //     });

  //   }
  // };



  handleSampleChange = async (selectedSampleOption) => {
    this.setState({ sample_id: selectedSampleOption.value, selectedSampleOption });
  };
  convertCsvToJson = (file: File): Promise<any[]> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const csvContent: any = reader.result;
        if (!csvContent.trim()) {
          reject(new Error('CSV content is empty.'));
          return;
        }
        csvtojson().fromString(csvContent).then(jsonData => resolve(jsonData))
      };
      reader.onerror = (error) => { reject(error); };
      reader.readAsText(file);
    });
  };


  modifiedConvertedJson = (obj, type) => {
    switch (type) {
      case 'bulkuserIds':
        return {
          userId: obj.userId || '', // Include user ID if type is userIds
          name: obj.name || '',
        };
      case 'bulkPhoneNumbers':
        return {
          phoneNumber: obj.phoneNumber || '', // Include phone number for phone-based notifications
          name: obj.name || '',
        };
      case 'bulkEmail':
        return {
          email: obj.email || '', // Include email if type is emails
          name: obj.name || '',
        };
      default:
        return {}; // Return an empty object if no valid type is provided
    }
  };

  // modifiedConvertedJson = (obj) => {
  //   return {
  //     name: obj.name,
  //     email: obj.email,
  //     // bulkEmailSheet: []
  //   };
  // }

  selectTempleteDescription = (id, optionName) => {
    // let selectedValue = e.target.value;
    let selectedValue = id;
    return Promise.resolve()
      .then(() => this.setState({ status: PageStatus.Submitting, selectedTempleteDescription: optionName }))
      .then(() => NewsLetterAPI.getonetempleates(selectedValue))
      .then((response) => {
        let responseData: any = response;
        this.setState({ status: PageStatus.Submitted, body: responseData.description != "" ? JSON.parse(responseData.description) : "", tempId: selectedValue, showHideCustomSelectBox: false });
      })
      .catch((error) => {
        this.setState({ status: PageStatus.Error, error: error.message, tempId: '' });
      });
  }

  showOptionsValue = () => { this.setState((prev)=>{return {showHideCustomSelectBox: !prev.showHideCustomSelectBox}}); }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  handleClickOutside = (e) => {
    if (
      this.selectRef.current && !this.selectRef.current.contains(e.target) &&
      this.optionsRef.current && !this.optionsRef.current.contains(e.target)
    ) {
      this.setState({ showHideCustomSelectBox: false });
    }
  };

  getAllTempletesFunc(type) {
    return Promise.resolve()
      .then(() => this.setState({ status: PageStatus.Submitting }))
      .then(() => NewsLetterAPI.getalltempleates(type))
      .then((response) => {
        let responseData: any = response;
        responseData.forEach((ele)=>{if(ele.id== this.state.tempId){this.setState({selectedTempleteDescription: ele.name})}});
        this.setState({ status: PageStatus.Submitted, allTempletes: responseData });
      })
      .catch((error) => {
        this.setState({ status: PageStatus.Error, error: error.message });
      });
  }

  deleteTemplate = (tempsId)=>{
    return Promise.resolve()
    .then(() => this.setState({ status: PageStatus.Submitting }))
    .then(() => NewsLetterAPI.deletetempleates({"id" : tempsId}))
    .then((response) => {
      let responseData: any = response;
      this.setState({ status: PageStatus.Submitted});
      this.getAllTempletesFunc({ "type" : this.state.notificationType == "email" || this.state.notificationType == "userId" ? "normal" : this.state.notificationType});
    })
    .catch((error) => {
      this.setState({ status: PageStatus.Error, error: error.message });
    });
  }
  // ==================== Notification Function ===============================
  handleChangeCheckbox = (event) => {
    const value = event.target.value;

    this.setState((prevState) => ({
      notificationType: value,
      disableEmails: value !== 'email',
      disablePhoneNumbers: value !== 'phoneNumber' && value !== 'sms' && value !== 'whatsapp',
      disableUserIds: value !== 'userId',
      disableBulkEmailSheet: value !== 'email',
      disablebulkPhoneNumbersSheet: value !== 'phoneNumber' && value !== 'sms' && value !== 'whatsapp',
      disablebulkuserIdsSheet: value !== 'userId',
      // Reset fields when they are disabled
      emails: value === 'email' ? prevState.emails : [], // Reset emails if email is not selected
      phoneNumbers: (value === 'phoneNumber' || value === 'sms' || value === 'whatsapp') ? prevState.phoneNumbers : [], // Reset phoneNumbers if phoneNumber, sms or whatsapp is not selected
      userIds: value === 'userId' ? prevState.userIds : [], // Reset userIds if userId is not selected
      bulkEmailSheet: value === 'email' ? prevState.bulkEmailSheet : '', // Reset bulkEmailSheet if email is not selected
      bulkPhoneNumbersSheet: (value === 'phoneNumber' || value === 'sms' || value === 'whatsapp') ? prevState.bulkPhoneNumbersSheet : '', // Reset bulkPhoneNumbersSheet if phoneNumber, sms or whatsapp is not selected
      bulkuserIdsSheet: value === 'userId' ? prevState.bulkuserIdsSheet : '', // Reset bulkuserIdsSheet if userId is not selected
      cat: '',
      selectedTempleteDescription: '',
      tempId: '',
    }), ()=>this.getAllTempletesFunc({"type" : value == "email" || value == "userId" ? "normal" : value}));
  };




  // ===============================================================

  render() {
    const { notificationType } = this.state;
    return (
      <Modal
        centered
        size="xl"
        backdrop="static"
        onHide={this.props.onClose}
        show={this.props.show}
        style={{ zIndex: 1201 }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            NewsLetter
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: '78vh', overflow: 'auto' }}>
          <Show when={this.state.status === PageStatus.Loading}>
            <div className="d-flex justify-content-center w-100 p-5">
              <Spinner animation="border" variant="primary" />
            </div>
          </Show>

          <Alert variant="danger" show={this.state.status === PageStatus.Error}>
            {this.state.error}
          </Alert>

          <form onSubmit={this.props.handleSubmit(
            (event) => this.onSubmit(),
          )}
          >
            {/* ================================================================= */}
            <div className="row">
              <div className="col-12">
                <label htmlFor="notificationType">Notification Type *</label>
                <div className="d-flex">
                  <div className="form-check me-3 mx-3">
                    <input
                      type="radio"
                      value="email"
                      checked={this.state.notificationType === 'email'}
                      onChange={this.handleChangeCheckbox}
                      id="email"
                    />
                    <label className="form-check-label" htmlFor="email">Email</label>
                  </div>

                  <div className="form-check mx-3">
                    <input
                      type="radio"
                      value="sms"
                      checked={this.state.notificationType === 'sms'}
                      onChange={this.handleChangeCheckbox}
                      id="sms"
                    />
                    <label className="form-check-label" htmlFor="sms">SMS</label>
                  </div>
                  <div className="form-check mx-3">
                    <input
                      type="radio"
                      value="whatsapp"
                      checked={this.state.notificationType === 'whatsapp'}
                      onChange={this.handleChangeCheckbox}
                      id="whatsapp"
                    />
                    <label className="form-check-label" htmlFor="whatsapp">WhatsApp</label>
                  </div>
                  <div className="form-check mx-3">
                    <input
                      type="radio"
                      value="userId"
                      checked={this.state.notificationType === 'userId'}
                      onChange={this.handleChangeCheckbox}
                      id="userId"
                    />
                    <label className="form-check-label" htmlFor="userId">Inbox</label>
                  </div>
                </div>
              </div>
            </div>


            {/* ======================================================= */}
            <div className="row mt-2">
              <div className="col">
                <label htmlFor="name">Name</label>
                <input
                  className="form-control"
                  name="name"
                  onChange={(e) => this.setState({ name: e.target.value })}
                  value={this.state.name}
                  placeholder="Enter here"
                />
              </div>
              <div className="col">
                <label htmlFor="sendDate">Date*</label>
                <input
                  type="datetime-local"
                  className="form-control"
                  id="sendDate"
                  name="sendDate"
                  onChange={(e) => this.setState({ sendDate: e.target.value })}
                  value={this.state.sendDate}
                  required

                />
              </div>
            </div>

            <div className="row mt-2">
              <div className="col">
                <label htmlFor="description">Subject</label>
                <input
                  className="form-control"
                  type="subject"
                  name="subject"
                  onChange={(e) => this.setState({ subject: e.target.value })}
                  value={this.state.subject}
                  placeholder="Enter here"

                />
              </div>
              <div ref={this.selectRef} onClick={this.showOptionsValue} className={`col ${this.state.showHideSelectTempField}`}>
              <label htmlFor="description">Select Template</label>
                <div className="custom-dropdown">
                  <div
                    name="selectTemplate"
                    id="selectTemplate"
                    // onChange={this.selectTempleteDescription}
                    // value={this.state.selectedTempleteDescription || this.state.tempId}
                    className="form-control"
                    style={{cursor: 'pointer'}} 
                  >
                    <div className='d-flex justify-content-between'>
                      {this.state.selectedTempleteDescription || 
                      <>
                        <span>--Choose--</span>
                        <span style={{fontSize:"0.6rem"}}><em className='fa fa-chevron-down align-items-center'></em></span>
                      </>
                      }
                    </div>
                    
                    {/* <option value="">--Choose--</option>
                    {this.state.allTempletes.length > 0 ? (
                      this.state.allTempletes.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))
                    ) : (
                      <option>No templates available</option>
                    )} */}
                  </div>
                  {this.state.showHideCustomSelectBox && 
                    <div ref={this.optionsRef} className="template-list">
                      {this.state.allTempletes.length > 0 ? (
                        this.state.allTempletes.map((item) => (
                          <div key={item.id} className={`template-item ${this.state.tempId == item.id ? "selected_temp_name" : ""}`}>
                            <span style={{cursor: 'pointer',marginLeft:'1rem'}} onClick={()=>{this.selectTempleteDescription(item.id, item.name)}}>{item.name}</span>
                            <button
                              className="delete-icon"
                              onClick={() => this.deleteTemplate(item.id)}
                            >
                              <i className="fa fa-trash text-danger cursor-pointer"></i>
                            </button>
                          </div>
                        ))
                      ) : 
                      (<span>No templates available</span>)}
                    </div>
                  }
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col">
                <label htmlFor="category">Category</label>
                <select 
                  name="category" 
                  id="category" 
                  className="form-control" 
                  value={this.state.cat} 
                  onChange={(e) => this.setState({ cat: this.state.notificationType != 'userId' ? "" : e.target.value })}
                  disabled={this.state.notificationType != 'userId'}
                  required={this.state.notificationType == 'userId'}
                >
                  <option value="">--Choose--</option>
                  <option value="surveyRelated">Survey Related</option>
                  <option value="rewardRelated">Reward Related</option>
                  <option value="redemptionRelated">Redemption Related</option>
                  <option value="general">General</option>
                </select>
              </div>
            </div>
            {notificationType !== 'whatsapp' && notificationType !== 'sms' && (
              <div className="row mt-2">
                <div className="col">
                  <label htmlFor="body">Body</label>
                  <ReactQuill
                    theme="snow" // other themes: 'bubble', 'core'
                    value={this.state.body}
                    onChange={(e) => this.setState({ body: e })}
                  />
                </div>
              </div>
            )}
            <div className="row mt-2">
              <div className="col-6">
                <label htmlFor="text">Emails</label>
                <CreatableSelect
                  name='emails'
                  id='emails'
                  isMulti
                  onChange={(selectedOptions) => this.handleChange(selectedOptions, 'emails')}
                  onCreateOption={(inputValue) => this.handleCreateOption(inputValue, 'emails')}
                  value={this.state.emails}
                  isDisabled={this.state.disableEmails}
                />
              </div>
              <div className="col-6">
                <label htmlFor="bulkEmail">Choose bulk emails</label>
                <input
                  type="file"
                  accept=".csv"
                  className="form-control"
                  id="bulkEmail"
                  name="bulkEmail"
                  onChange={(event) => this.handleFileChange(event, 'emails')}
                  disabled={this.state.disableBulkEmailSheet}
                />
              </div>
            </div>
            {/* ====================== Phone Numbers==================== */}

            <div className="row ">
              <div className="col-6">
                <label htmlFor="phoneNumbers" className="mt-2 ">Phone Numbers</label>
                <CreatableSelect
                  name='phoneNumbers'
                  id='phoneNumbers'
                  isMulti={false} // Change this to false for single select
                  onChange={(selectedOptions) => this.handleChange(selectedOptions, 'phoneNumbers')}
                  onCreateOption={(inputValue) => this.handleCreateOption(inputValue, 'phoneNumbers')}
                  value={this.state.phoneNumbers}
                  isDisabled={this.state.disablePhoneNumbers}
                />
              </div>
              <div className="col-6">
                <label htmlFor="bulkEmail" className="mt-2 ">Choose bulk Number</label>
                <input
                  type="file"
                  accept=".csv"
                  className="form-control"
                  id="bulkPhoneNumbers"
                  name="bulkPhoneNumbers"
                  onChange={(event) => this.handleFileChange(event, 'phoneNumbers')}
                  disabled={this.state.disablebulkPhoneNumbersSheet}
                />
              </div>
            </div>
            {/* ===================User IDs================== */}


            <div className="row ">
              <div className="col-6">
                <label htmlFor="userIds" className="mt-2 ">User IDs</label>
                <CreatableSelect
                  name='userIds'
                  id='userIds'
                  isMulti={false} // Change this to false for single select
                  onChange={(selectedOptions) => this.handleChange(selectedOptions, 'userIds')}
                  onCreateOption={(inputValue) => this.handleCreateOption(inputValue, 'userIds')}
                  value={this.state.userIds}
                  isDisabled={this.state.disableUserIds}
                />
              </div>
              <div className="col-6">
                <label htmlFor="bulkEmail" className="mt-2 ">Choose bulk User IDs</label>
                <input
                  type="file"
                  accept=".csv"
                  className="form-control "
                  id="bulkuserIds"
                  name="bulkuserIds"
                  onChange={(event) => this.handleFileChange(event, 'userIds')}
                  disabled={this.state.disablebulkuserIdsSheet}
                />
              </div>
            </div>
            {/* ===========ADD FILED ENHANCEMENT================ */}


            <Show when={this.props.id}>
              <div className="form-group">
                <label htmlFor='sample'>Samples</label>
                <Select
                  name='sample_id'
                  id='sample_id'
                  onChange={this.handleSampleChange}
                  value={this.state.selectedSampleOption}
                  options={this.state.samples}
                />
              </div>
            </Show>
            <Alert variant="danger" show={!!this.state.error} className="mt-2">
              {this.state.error}
            </Alert>

            <div className="d-flex align-items-center mt-2">
              <button
                type="submit"
                // disabled={!this.state.body || !this.state.emails.length}
                // disabled={!this.state.body}
                className="btn btn-primary mr-3"
              >
                Submit
              </button>

              <Show when={this.state.status === PageStatus.Submitting}>
                <Spinner animation="border" variant="primary" />
              </Show>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    );
  }
}

const dataFormRedux = reduxForm < any, any> ({
  form: 'dataForm',
})(Form);

const dataFormWithRouter = withRouter(dataFormRedux);

export { dataFormWithRouter as Form };
