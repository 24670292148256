import React, { Component } from 'react';
import {
    Alert, Button, Spinner, Table,
} from 'react-bootstrap';
import { PageStatus } from 'enums';
import {Show} from "../../Layout";
import CardHeader from "../Card/CardHeader";
import GridContainer from "../Grid/GridContainer";
import Card from "../Card/Card";
import moment from "moment/moment";
import {exportToExcel} from "../../Utils/ExportToExcel";
import {AuthAPI, SamplesAPI} from "../../API";
import {Confirmation} from "../../Shared/Confirmation";
import ShowDataAccording2PageSize from '../ShowEntries/ShowEntries';
import PaginationNumbering from '../ShowEntries/Pagination';

const MODAL_TYPES = {
    NONE: 'NONE',
    CREATE: 'CREATE',
    UPDATE: 'UPDATE',
    DELETE: 'DELETE',
    DETAILS: 'DETAILS',
};

type State = {
    status: PageStatus,
    error: string | null,
    formType: string,
    data: any[],
    id?: string | null,
    filteredData: any[],
    filters: {
        firstName: '',
        email: '',
        phoneNumber: ''
    },
    pageSize : any,
    currentPageNumber : any,
    totalResponseData : any,
    totalResponsePages : any,
    loading: any,
};

export class DeletedPanelistsOnly extends Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            status: PageStatus.None,
            error: null,
            formType: MODAL_TYPES.NONE,
            data: [],
            id: null,
            filteredData: [],
            filters: {
                firstName: '',
                email: '',
                phoneNumber: ''
            },
            pageSize : 10,
            currentPageNumber : 1,
            totalResponseData : '',
            totalResponsePages : '',
            loading: false,
        };
        this.fetchList = this.fetchList.bind(this);
    }

    componentDidMount() {
        this.fetchList()
    }

    fetchList(): Promise<void> {
        const {pageSize, currentPageNumber, filters} = this.state; 
        return Promise.resolve()
            .then(() => this.setState({ status: PageStatus.Loading }))
            .then(() => AuthAPI.usersList(pageSize, currentPageNumber, filters, 'deleteRequestOnly'))
            .then((users) => {
                let responseData: any = users;         
                this.setState({ data: responseData.data, filteredData: responseData.data, totalResponseData: responseData.totalCount, totalResponsePages: responseData.totalPage, status: PageStatus.Loaded });
              })
            .catch((error) => {
                this.setState({ error: error.message, status: PageStatus.Error });
            });
    }

    isShowDetailModal(): boolean {
        return this.state.formType === MODAL_TYPES.DETAILS
            || this.state.formType === MODAL_TYPES.DELETE;
    }


    handleFilterChange = (e) => {
        const { name, value } = e.target;
        console.log(name, value)
        this.setState(
            (prevState) => ({
                filters: {
                    ...prevState.filters,
                    [name]: value,
                },
            }),
        );
    };
    
    applyFilters = () => {
        if (this.state.currentPageNumber == 1) { this.fetchList(); return; }
        this.setState({currentPageNumber : 1})
    };

    // applyFilters = () => {
    //     const { data } = this.state;
    //     const { filters } = this.state;
    //     const filteredData = data.filter((user) => {
    //         return Object.keys(filters).every((key) => {
    //             if (filters[key] === '') return true;
    //             if (key === 'createdAt') {
    //                 return new Date(user[key]).toDateString() === new Date(filters[key]).toDateString();
    //             }
    //             if (key === 'firstName') {
    //                 return String(`${user.basic_profile['firstName']}${user.basic_profile['lastName']}`).toLowerCase().includes(String(filters[key]).toLowerCase());
    //             }
    //             return String(user[key]).toLowerCase().includes(String(filters[key]).toLowerCase());
    //         });
    //     });
    //     this.setState({filteredData});
    // }


    clearFilter = async () => {
        await this.setState({ filters: {
                firstName: '',
                email: '',
                phoneNumber: ''
            },
            pageSize : 10,
            currentPageNumber : 1,
            totalResponseData : '',
            totalResponsePages : ''
          });
        this.fetchList();
    }

    async handleExport(){
        // let obj = this.state.filteredData.map((user) => {
        //     return {
        //         ...user,
        //         name: `${user.basic_profile.firstName} ${user.basic_profile.lastName}`,
        //         'Date Of Birth': user.basic_profile.dateOfBirth
        //     }
        // })
        // exportToExcel(obj, 'BasicProfileOnly');
        this.setState({ loading: true });
        const {pageSize, currentPageNumber, filters} = this.state; 
        try {
            const recievedData = await AuthAPI.usersList('All', currentPageNumber, filters, 'deleteRequestOnly');
            // const combinedData = [...rewardsData, ...this.state.filteredData];
            exportToExcel(recievedData.data, 'deletedPanelistsOnly');
        } catch (error) {
            console.error("Error exporting data:", error);
        }finally {
            this.setState({ loading: false });
        }
    };


    deleteAcceptActions(id): Promise<void> {
        return Promise.resolve()
            .then(() => this.setState({ status: PageStatus.Loading }))
            .then(() => AuthAPI.deleteActions(id, 'accept'))
            .then((users) => {
                this.fetchList()
                this.setState({ data: users,  status: PageStatus.Loaded });
            })
            .catch((error) => {
                this.setState({ error: error.message, status: PageStatus.Error });
            });
    }

    deleteRejectActions(id): Promise<void> {
        return Promise.resolve()
            .then(() => this.setState({ status: PageStatus.Loading }))
            .then(() => AuthAPI.deleteActions(id, 'reject'))
            .then((users) => {
                this.fetchList()
                this.setState({ data: users,  status: PageStatus.Loaded });
            })
            .catch((error) => {
                this.setState({ error: error.message, status: PageStatus.Error });
            });
    }
    
    handlePageSizeChange = (newValue : any) => { this.setState({ pageSize: newValue, currentPageNumber: 1 }); };
    handlePageNumberChange = (newValue : any) => { this.setState({ currentPageNumber: newValue }); };
  
    componentDidUpdate(prevProps: {}, prevState: State) {
        if (prevState.pageSize !== this.state.pageSize || prevState.currentPageNumber !== this.state.currentPageNumber) { this.fetchList(); }
    };

    render() {
        // const { filteredData, filters } = this.state;
        const { filteredData, filters, pageSize, currentPageNumber, totalResponseData, totalResponsePages } = this.state;
        return (
            <>
                <GridContainer>
                    <div className='w-100 mb-3'>
                        <CardHeader color="primary">
                            <div className="d-flex align-items-center justify-content-between">
                                <h4>Delete Request Panelists</h4>
                            </div>
                        </CardHeader>
                    </div>
                </GridContainer>

                <div className="jumbotron bg-white p-3 border shadow-sm">
                    <div className='mb-3'>Filter</div>

                    <form>
                        <div className="row">
                            <div className="col">
                                <label>Name</label>
                                <input type="text"
                                       className="form-control"
                                       placeholder="Enter..."
                                       name="firstName"
                                       value={filters.firstName}
                                       onChange={this.handleFilterChange}
                                />
                            </div>
                            <div className="col">
                                <label>Email</label>
                                <input type="email"
                                       className="form-control"
                                       placeholder="Enter..."
                                       name="email"
                                       value={filters.email}
                                       onChange={this.handleFilterChange}
                                />
                            </div>
                            <div className="col">
                                <label>Phone Number</label>
                                <input type="text"
                                       className="form-control"
                                       placeholder="Enter..."
                                       name="phoneNumber"
                                       value={filters.phoneNumber}
                                       onChange={this.handleFilterChange}
                                />
                            </div>
                        </div>
                    </form>

                    <div className="jumbotron bg-white p-1 mt-2 shadow-sm">
                        <button type="button" className="btn btn-success" onClick={() => this.applyFilters()}>Filter Panelists</button>
                        <button type="button" className="btn btn-info ml-1" onClick={() => this.handleExport()}>Export</button>
                        <button type="button" className="btn btn-danger ml-1" onClick={() => this.clearFilter()}>Clear Filter</button>
                    </div>


                </div>

                <div className="jumbotron bg-white p-3 border shadow-sm">
                    <Alert variant="danger" show={this.state.status === PageStatus.Error}>
                        {this.state.error}
                    </Alert>

                    <Show when={this.state.status === PageStatus.Loading}>
                        <div className="d-flex justify-content-center w-100 p-5">
                            <Spinner animation="border" variant="primary" />
                        </div>
                    </Show>

                    <Show when={this.state.status === PageStatus.Loaded}>

                        <Show when={!this.state.filteredData.length}>
                            <Alert variant="info" show={!this.state.filteredData.length}>
                                No data is available
                            </Alert>
                        </Show>

                        <Show when={!!this.state.filteredData.length}>
                            <div className='mb-2'>  
                                <ShowDataAccording2PageSize 
                                currentPageSize={pageSize} 
                                currentPage={currentPageNumber}
                                sendPageSize={this.handlePageSizeChange}
                                showTotalData={totalResponseData} 
                                sendLastPage={totalResponsePages}
                                />
                            </div>
                            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '9999' }}>
                                {this.state.loading && (
                                    <img style={{ height: "415px", }}
                                        src="../loadernew.gif"
                                        alt="Loading..."
                                    />
                                )}
                            </div>
                            <Table responsive size="sm" bordered>
                                <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Request ID</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Phone Number</th>
                                    <th>City</th>
                                    <th>Date Of Birth</th>
                                    <th>CreatedAt</th>
                                    <th>Action</th>
                                </tr>
                                </thead>

                                <tbody>
                                {
                                    this.state.filteredData.map((info, index) => (
                                        <tr key={info.id}>
                                            {/* <td>{this.state.filteredData.length - index}</td> */}
                                            <td>{((pageSize)*(currentPageNumber-1))+(index + 1)}</td>                                            
                                            <td>{info.id}</td>
                                            <td>
                                              <span
                                                  aria-label="button"
                                                  role="button"
                                                  tabIndex={0}
                                                  className="text-primary"
                                                  onKeyPress={() => null}
                                                  onClick={() => {
                                                      this.setState({
                                                          formType: MODAL_TYPES.NONE,
                                                          id: info.id,
                                                      });
                                                  }}
                                                  dangerouslySetInnerHTML={{
                                                      __html: info.firstName || info.lastName ? `${info.firstName} ${info.lastName}` : '-',
                                                  }}
                                              />
                                            </td>
                                            <td>{info.email ? info.email : "-"}</td>
                                            <td>{info.phoneNumber ? info.phoneNumber : "-"}</td>
                                            <td>{info.city ? info.city : '-'}</td>
                                            <td>{info.dateOfBirth ? moment(info.dateOfBirth).format('MM/DD/YYYY') : '-'}</td>
                                            <td>{moment(info.createdAt).format('MM/DD/YYYY HH:mm A')}</td>
                                            <td>
                                                <Confirmation onAction={() => this.deleteAcceptActions(info.id)} body="Are you sure want to approve delete request ?">
                                                    <Button
                                                        variant="success"
                                                        size="sm"
                                                        disabled={info.deleteConfirmDate}
                                                        className="mx-1"
                                                    >
                                                        Approved
                                                    </Button>
                                                </Confirmation>
                                                <Confirmation onAction={() => this.deleteRejectActions(info.id)} body="Are you sure want to reject delete request ?">
                                                    <Button
                                                        variant="danger"
                                                        size="sm"
                                                        disabled={info.deleteConfirmDate}
                                                        className="mx-1"
                                                    >
                                                        Reject
                                                    </Button>
                                                </Confirmation>
                                            </td>
                                        </tr>
                                    ))
                                }
                                </tbody>

                            </Table>
                            <div>
                                <PaginationNumbering 
                                currentPage={currentPageNumber} 
                                returnPagenumber={this.handlePageNumberChange}
                                showTotalData={totalResponseData} 
                                sendLastPage={totalResponsePages}
                                />
                            </div>
                        </Show>
                    </Show>
                </div>
            </>
        );
    }
}
