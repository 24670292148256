import React, { Component } from 'react';
import {
    Alert, Spinner, Table,
} from 'react-bootstrap';

import { PageStatus } from 'enums';
import {AuthAPI} from "../../API";
import {Show} from "../../Layout";
import GridContainer from "../Grid/GridContainer";
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import moment from 'moment'
import {exportToExcel} from "../../Utils/ExportToExcel";
import ShowDataAccording2PageSize from '../ShowEntries/ShowEntries';
import PaginationNumbering from '../ShowEntries/Pagination';

const MODAL_TYPES = {
    NONE: 'NONE',
    CREATE: 'CREATE',
    UPDATE: 'UPDATE',
    DELETE: 'DELETE',
    DETAILS: 'DETAILS',
};

type State = {
    status: PageStatus,
    error: string | null,
    formType: string,
    rewards: any[],
    queryId?: string | null,
    filteredData: any,
    filters: any,
    pageSize : any,
    currentPageNumber : any,
};

export class RewardsList extends Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            status: PageStatus.None,
            error: null,
            formType: MODAL_TYPES.NONE,
            rewards: [],
            queryId: null,
            filteredData: [],
            filters: {
                // rewardDate: '',
                rewardfrom: '',
                rewardto: '',
                rewardType: ''
            },
            pageSize: 10,
            loading: false,
            currentPageNumber: 1,
            totalResponseData: '',
            totalResponsePages: ''
        };
    }


    handleFilterChange = (e) => {
        const { name, value } = e.target;
        this.setState(
            (prevState) => ({
                filters: {
                    ...prevState.filters,
                    [name]: value,
                },
            }),
        );
    };

    // applyFilters = () => {
    //     const { rewards } = this.state;
    //     const { filters } = this.state;
    //     console.log(filters)
    //     const filteredData = rewards.filter((user) => {
    //         return Object.keys(filters).every((key) => {
    //             if (filters[key] === '') return true;
    //             if (key === 'rewardDate' || key === 'updatedAt' || key === 'createdAt') {
    //                 return new Date(user[key]).toDateString() === new Date(filters[key]).toDateString();
    //             }
    //             return String(user[key]).toLowerCase().includes(String(filters[key]).toLowerCase());
    //         });
    //     });
    
    //     this.setState({ filteredData });
    // };
    applyFilters = () => {
        // const { filters } = this.state;
    
        // try {
        //     const newData = await AuthAPI.rewardsList(this.state.pageSize, this.state.currentPageNumber, filters);
        //     this.setState({
        //         rewards: newData.data,
        //         filteredData: newData.data, // Update filteredData with the new data from API
        //         totalResponseData: newData.totalCount,
        //         totalResponsePages: newData.totalPage,
        //     });
        // } catch (error) {
        //     console.error("Failed to fetch filtered rewards:", error);
        // }
        if (this.state.currentPageNumber == 1) { this.fetchList(); return; }
        this.setState({currentPageNumber : 1})
    };
    
    clearFilter = async () => {
        await this.setState({
            filters: {
                rewardfrom: '',
                rewardto: '',
                // rewardDate: '',
                rewardType: ''
            },
            pageSize : 10,
            currentPageNumber : 1,
            totalResponseData : '',
            totalResponsePages : ''
        })
        this.fetchList();
    }

    componentDidMount() {
        this.fetchList()
    }

    // fetchList(): Promise<void> {
    //     return Promise.resolve()
    //         .then(() => this.setState({ status: PageStatus.Loading }))
    //         .then(() => AuthAPI.rewardsList(this.state.pageSize, this.state.currentPageNumber))
    //         .then((users) => {
    //             this.setState({ rewards: users, filteredData: users, status: PageStatus.Loaded });
    //         })
    //         .catch((error) => {
    //             this.setState({ error: error.message, status: PageStatus.Error });
    //         });
    // }
    fetchList(): Promise<void> {
        return Promise.resolve()
            .then(() => this.setState({ status: PageStatus.Loading }))
            .then(() => AuthAPI.rewardsList(this.state.pageSize, this.state.currentPageNumber, this.state.filters)) // Pass filters here
            .then((response) => {
                let responseData: any = response;
                this.setState({
                    rewards: responseData.data,
                    filteredData: responseData.data,
                    totalResponseData: responseData.totalCount,
                    totalResponsePages: responseData.totalPage,
                    status: PageStatus.Loaded
                });
            })
            .catch((error) => {
                this.setState({ error: error.message, status: PageStatus.Error });
            });
    }



    async handleExport() {
        this.setState({ loading: true });
        try {
            const rewardsData = await AuthAPI.rewardsListexport();
            // const combinedData = [...rewardsData, ...this.state.filteredData];
            exportToExcel(rewardsData, 'Rewards');
        } catch (error) {
            console.error("Error exporting data:", error);
        }finally {
            this.setState({ loading: false });
        }
    }


    handlePageSizeChange = (newValue : any) => { this.setState({ pageSize: newValue, currentPageNumber: 1 }); };
    handlePageNumberChange = (newValue : any) => { this.setState({ currentPageNumber: newValue }); };

    componentDidUpdate(prevProps: {}, prevState: State) {
        if (
            prevState.pageSize !== this.state.pageSize ||
            prevState.currentPageNumber !== this.state.currentPageNumber
        ) {
            this.fetchList(); // Fetch the list when page size or number changes
        }
    };


    render() {
        const { filteredData, filters, pageSize, currentPageNumber, totalResponseData, totalResponsePages } = this.state;
        return (
            <>
                <GridContainer>
                    <div className='w-100 mb-3'>
                        <CardHeader color="primary">
                            <div className="d-flex align-items-center justify-content-between">
                                <h4 className="text-white">Rewards</h4>
                            </div>
                        </CardHeader>
                    </div>
                </GridContainer>
                <div className="jumbotron bg-white p-3 border shadow-sm">
                    <div className='mb-3'>Filter</div>

                    <form>
                        <div className="row">
                            <div className="col">
                                <label>Reward Date From</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    name="rewardfrom"
                                    placeholder="select date"
                                    value={filters.rewardfrom}
                                    onChange={this.handleFilterChange}
                                />
                            </div>
                            <div className="col">
                                <label>Reward Date To</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    name="rewardto"
                                    placeholder="select date"
                                    value={filters.rewardto}
                                    onChange={this.handleFilterChange}
                                />
                            </div>
                            <div className="col">
                                <label>Reward Type</label>
                                <select
                                    style={{
                                        width: '100%',
                                        display: 'block',
                                        height: '40px',
                                        lineHeight: '1.5',
                                        color: '#495057',
                                        backgroundColor: '#fff',
                                        backgroundClip: 'padding-box',
                                        border: '1px solid #ced4da',
                                        borderRadius: '5px',
                                        transition:
                                            'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
                                    }}
                                    name='rewardType'
                                    id='type'
                                    required
                                    value={filters.rewardType}
                                    onChange={this.handleFilterChange}
                                >
                                    <option value='' disabled>--Choose--</option>
                                    <option value='Survey'>Survey</option>
                                    <option value='Profile Completed'>Profile Completed</option>
                                    <option value='Referral'>Referral</option>
                                    <option value='Sign-in Bonus'>Sign-in Bonus</option>
                                </select>
                            </div>
                        </div>
                    </form>

                    <div className="jumbotron bg-white p-1 mt-2 shadow-sm">
                        <button type="button" className="btn btn-success" onClick={() => this.applyFilters()}>Filter Rewards</button>
                        <button type="button" className="btn btn-info ml-1" onClick={() => this.handleExport()}>Export</button>
                        <button type="button" className="btn btn-danger ml-1" onClick={() => this.clearFilter()}>Clear Filter</button>
                    </div>


                </div>

                <div className="jumbotron bg-white p-3 border shadow-sm">
                    {/* <div className='mb-3'>Rewards Details</div> */}
                    <Alert variant="danger" show={this.state.status === PageStatus.Error}>
                        {this.state.error}
                    </Alert>
                    <Show when={this.state.status === PageStatus.Loading}>
                        <div className="d-flex justify-content-center w-100 p-5">
                            <Spinner animation="border" variant="primary" />
                        </div>
                    </Show>

                    <Show when={this.state.status === PageStatus.Loaded}>


                        <Show when={!this.state.filteredData.length}>
                            <Alert variant="info" show={!this.state.rewards.length}>
                                No data is available
                            </Alert>
                        </Show>


                        <Show when={!!this.state.filteredData.length}>
                            <div className='mb-2'>
                                <ShowDataAccording2PageSize
                                    currentPageSize={pageSize}
                                    currentPage={currentPageNumber}
                                    sendPageSize={this.handlePageSizeChange}
                                    showTotalData={totalResponseData}
                                    sendLastPage={totalResponsePages}
                                />
                            </div>
                            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '9999' }}>
                                {this.state.loading && (
                                    <img style={{ height: "415px", }}
                                        src="../loadernew.gif"
                                        alt="Loading..."
                                    />
                                )}
                            </div>
                            <Table responsive size="sm" bordered>
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Points</th>
                                        <th>Reward type</th>
                                        <th>Name</th>
                                        <th>UserId</th>
                                        <th>Reward Date</th>
                                        <th>Reward Status</th>
                                        <th>Created At</th>
                                        <th>Updated At</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.filteredData.map((redemption, index) => (
                                            <tr key={redemption.id}>
                                                <td>{((pageSize) * (currentPageNumber - 1)) + (index + 1)}</td>
                                                <td>
                                                    <span
                                                        aria-label="button"
                                                        role="button"
                                                        tabIndex={0}
                                                        className="text-primary"
                                                        onKeyPress={() => null}
                                                        onClick={() => {
                                                            this.setState({
                                                                formType: MODAL_TYPES.NONE,
                                                                queryId: redemption.id,
                                                            });
                                                        }}
                                                    >{redemption.points}</span>
                                                </td>
                                                <td>{redemption.rewardType}</td>
                                                <td>{redemption.basic_profile ? `${redemption.basic_profile.firstName} ${redemption.basic_profile.lastName}` : 'NA'}</td>
                                                <td>{redemption.userId}</td>
                                                <td>{moment(redemption.rewardDate).format('MM/DD/YYYY HH:mm A')}</td>
                                                <td>{redemption.rewardStatus}</td>
                                                <td>{moment(redemption.createdAt).format('MM/DD/YYYY HH:mm A')}</td>
                                                <td>{moment(redemption.updatedAt).format('MM/DD/YYYY HH:mm A')}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>

                            </Table>
                            <div>
                                <PaginationNumbering
                                    currentPage={currentPageNumber}
                                    returnPagenumber={this.handlePageNumberChange}
                                    showTotalData={totalResponseData}
                                    sendLastPage={totalResponsePages}
                                />
                            </div>
                        </Show>
                    </Show>
                </div>
            </>
        );
    }
}
