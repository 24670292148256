import React, { Component } from 'react';
import {
    Alert, Button, Spinner, Table,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Form } from './Form';
import { Details } from './Details';

import { PageStatus } from 'enums';
import { Show } from "../../Layout";
import CardHeader from "../Card/CardHeader";
import GridContainer from "../Grid/GridContainer";
import moment from "moment/moment";
import { WhatsappTempAPI } from "../../API/WhatsappTempAPI";
import {Confirmation} from "../../Shared/Confirmation";
import { exportToExcel } from "../../Utils/ExportToExcel";
import ShowDataAccording2PageSize from '../ShowEntries/ShowEntries';
import PaginationNumbering from '../ShowEntries/Pagination';

const MODAL_TYPES = {
    NONE: 'NONE',
    CREATE: 'CREATE',
    UPDATE: 'UPDATE',
    DELETE: 'DELETE',
    DETAILS: 'DETAILS',
};

type State = {
    status: PageStatus,
    error: string | null,
    formType: string,
    id?: string | null,
    filteredData: any[],
    filters: {
        type: '',
    },
    pageSize: any,
    currentPageNumber: any,
    loading: any,
};

export class WhatsappTempletes extends Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            status: PageStatus.None,
            error: null,
            formType: MODAL_TYPES.NONE,
            id: null,
            filteredData: [],
            filters: {
                type: '',
            },
            options: [
                { label: "Normal", value: "normal" },
                { label: "Reminder One", value: "reminder_one" },
                { label: "Reminder Two", value: "reminder_two" },
            ],
            pageSize: 10,
            currentPageNumber: 1,
            totalResponseData: '',
            totalResponsePages: '',
            loading: false,
        };
        this.fetchList = this.fetchList.bind(this);
    }

    componentDidMount() {
        this.fetchList()
    }

    fetchList(): Promise<void> {
        const { pageSize, currentPageNumber, filters } = this.state;
        return Promise.resolve()
            .then(() => this.setState({ status: PageStatus.Loading }))
            .then(() => WhatsappTempAPI.getAll(pageSize, currentPageNumber, filters))
            .then((response) => {
                let responseData: any = response;
                this.setState({ filteredData: responseData.data, totalResponseData: responseData.totalCount, totalResponsePages: responseData.totalPage, status: PageStatus.Loaded });
            })
            .catch((error) => {
                this.setState({ error: error.message, status: PageStatus.Error });
            });
    }

    isShowDetailModal(): boolean {
        return this.state.formType === MODAL_TYPES.DETAILS
            || this.state.formType === MODAL_TYPES.DELETE;
    }


    handleFilterChange = (e) => {
        const { name, value } = e.target;
        this.setState(
            (prevState) => ({
                filters: {
                    ...prevState.filters,
                    [name]: value,
                },
            }),
        );
    };

    applyFilters = () => {
        if (this.state.currentPageNumber == 1) { this.fetchList(); return; }
        this.setState({ currentPageNumber: 1 })
    };

    clearFilter = async () => {
        await this.setState({
            filters: {
                type: '',
            },
            pageSize: 10,
            currentPageNumber: 1,
            totalResponseData: '',
            totalResponsePages: ''
        });
        this.fetchList();
    }

      async handleExport(){
        const {currentPageNumber} = this.state; 
        this.setState({ loading: true });
        try {
            const recievedData: any = await WhatsappTempAPI.getAll('All', currentPageNumber, {});
            exportToExcel(recievedData.data, 'All Whatsapp Templates');
        } catch (error) {
            console.error("Error exporting data:", error);
        }finally {
            this.setState({ loading: false });
        }
      };

    deleteWhatsappTemp = (tempId)=>{
        return Promise.resolve()
            .then(() => this.setState({ status: PageStatus.Loading }))
            .then(() => WhatsappTempAPI.delete(tempId))
            .then(() => {
                // alert('Templete deleted successfully');
                this.setState({ status: PageStatus.Loaded });
                this.fetchList();
            })
            .catch((error) => {
                this.setState({ error: error.message, status: PageStatus.Error });
            });
    }

    handlePageSizeChange = (newValue: any) => { this.setState({ pageSize: newValue, currentPageNumber: 1 }); };
    handlePageNumberChange = (newValue: any) => { this.setState({ currentPageNumber: newValue }); };

    componentDidUpdate(prevProps: {}, prevState: State) {
        if (prevState.pageSize !== this.state.pageSize || prevState.currentPageNumber !== this.state.currentPageNumber) { this.fetchList(); }
    };

    render() {
        const { filteredData, options, filters, pageSize, currentPageNumber, totalResponseData, totalResponsePages } = this.state;
        return (
            <>
                <GridContainer>
                    <div className='w-100 mb-3'>
                        <CardHeader color="primary">
                            <div className="d-flex align-items-center justify-content-between">
                                <h4>Whatsapp Templates</h4>
                                <div>
                                    <Button
                                        onClick={() => {
                                            return this.setState({
                                                formType: MODAL_TYPES.CREATE,
                                            });
                                        }}
                                        variant="primary"
                                        size="sm"
                                        className="mx-1"
                                    >
                                        <FontAwesomeIcon icon={['fas', 'plus']} className="mr-2" />
                                        Create
                                    </Button>
                                </div>
                            </div>
                        </CardHeader>
                    </div>
                </GridContainer>

                <div className="jumbotron bg-white p-3 border shadow-sm">
                    <div className='mb-3'>Filter</div>
                    <form>
                        <div className="row">
                            <div className="col">
                                <label>Template Type</label>
                                <select 
                                    name="type" 
                                    id="type"
                                    onChange={this.handleFilterChange}
                                    value={filters.type}
                                    className="form-control"
                                    >
                                    <option value=''>--Choose Type--</option>
                                        {options.length > 0 ? options.map((ele, index) => (
                                        <option key={index} value={ele.value}>{ele.label}</option>
                                        )) : ''}
                                </select>
                            </div>
                        </div>
                    </form>
                    <div className="jumbotron bg-white p-1 mt-2 shadow-sm">
                        <button type="button" className="btn btn-success" onClick={() => this.applyFilters()}>Filter Templates</button>
                        <button type="button" className="btn btn-info ml-1" onClick={() => this.handleExport()}>Export</button>
                        <button type="button" className="btn btn-danger ml-1" onClick={() => this.clearFilter()}>Clear Filter</button>
                    </div>
                </div>

                <div className="jumbotron bg-white p-3 border shadow-sm">
                    <Alert variant="danger" show={this.state.status === PageStatus.Error}>
                        {this.state.error}
                    </Alert>

                    <Show when={this.state.status === PageStatus.Loading}>
                        <div className="d-flex justify-content-center w-100 p-5">
                            <Spinner animation="border" variant="primary" />
                        </div>
                    </Show>

                    <Show when={this.state.status === PageStatus.Loaded}>
                        <Show when={this.state.formType === MODAL_TYPES.CREATE}>
                            <Form
                                show={this.state.formType === MODAL_TYPES.CREATE}

                                onClose={() => this.setState({
                                    formType: MODAL_TYPES.NONE,
                                })}
                                onSubmit={(id) => {
                                    this.fetchList();
                                    this.setState({
                                        formType: MODAL_TYPES.NONE,
                                    });
                                }}
                            />
                        </Show>

                        <Show when={!filteredData.length}>
                            <Alert variant="info" show={!filteredData.length}>
                                No data is available
                            </Alert>
                        </Show>

                        <Show when={!!filteredData.length}>
                            {/* <Show when={this.isShowDetailModal()}>
                                <Details
                                    id={this.state.id}
                                    onClose={() => this.setState({
                                        formType: MODAL_TYPES.NONE,
                                        id: null,
                                    })}
                                    onUpdate={() => {
                                        this.setState({
                                            formType: MODAL_TYPES.UPDATE,
                                        });
                                    }}
                                    onDelete={(id) => {
                                        this.fetchList();
                                        this.setState({
                                            formType: MODAL_TYPES.NONE,
                                            id: null,
                                        });
                                    }}
                                />
                            </Show> */}

                            <Show when={this.state.formType === MODAL_TYPES.UPDATE}>
                                <Form
                                    show={this.state.formType === MODAL_TYPES.UPDATE}
                                    id={this.state.id}

                                    onClose={() => this.setState({
                                        formType: MODAL_TYPES.NONE,
                                        id: null,
                                    })}
                                    onSubmit={(id) => {
                                        this.fetchList();
                                        this.setState({
                                            formType: MODAL_TYPES.DETAILS, id: id,
                                        });
                                    }}
                                />
                            </Show>
                            <div className='mb-2'>
                                <ShowDataAccording2PageSize
                                    currentPageSize={pageSize}
                                    currentPage={currentPageNumber}
                                    sendPageSize={this.handlePageSizeChange}
                                    showTotalData={totalResponseData}
                                    sendLastPage={totalResponsePages}
                                />
                            </div>
                            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '9999' }}>
                                {this.state.loading && (
                                    <img style={{ height: "415px", }}
                                        src="../loadernew.gif"
                                        alt="Loading..."
                                    />
                                )}
                            </div>
                            <Table responsive size="sm" bordered>
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Template ID</th>
                                        <th>Type</th>
                                        <th>CreatedAt</th>
                                        <th>UpdatedAt</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        filteredData.map((ele, index) => (
                                            <tr key={ele.id}>
                                                <td>{((pageSize) * (currentPageNumber - 1)) + (index + 1)}</td>
                                                <td>
                                                    <span
                                                        aria-label="button"
                                                        // role="button"
                                                        tabIndex={0}
                                                        className="text-primary"
                                                        // onKeyPress={() => null}
                                                        // onClick={() => {
                                                        //     this.setState({
                                                        //         formType: MODAL_TYPES.DETAILS,
                                                        //         id: ele.id,
                                                        //     });
                                                        // }}
                                                        dangerouslySetInnerHTML={{
                                                            __html: ele.tempid || 'NA',
                                                        }}
                                                    />
                                                </td>
                                                <td>{ele.type == "normal" ? "Normal" : ele.type == "reminder_one" ? "Reminder One" : ele.type == "reminder_two" ? "Reminder Two" : "NA"}</td>
                                                <td>{moment(ele.createdAt).format('MM/DD/YYYY HH:mm A')}</td>
                                                <td>{moment(ele.updatedAt).format('MM/DD/YYYY HH:mm A')}</td>
                                                <td>
                                                    <Confirmation onAction={() => this.deleteWhatsappTemp(ele.id)} body="Do you want to delete templete ?">
                                                        <Button
                                                            variant="danger"
                                                            size="sm"
                                                            className="mx-1"
                                                        >
                                                            Delete
                                                        </Button>
                                                    </Confirmation>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>

                            </Table>
                            <div>
                                <PaginationNumbering
                                    currentPage={currentPageNumber}
                                    returnPagenumber={this.handlePageNumberChange}
                                    showTotalData={totalResponseData}
                                    sendLastPage={totalResponsePages}
                                />
                            </div>
                        </Show>
                    </Show>
                </div>
            </>
        );
    }
}
