import * as _ from 'lodash';
import { api } from '../axiosConfig';
import {
    createBasicProfileURL,
    getProfile,
    loginURL,
    redemptionRequest, redemptionRespondentRequestURL,
    referralsUrl,
    registrationURL, rewardsAPIURL,
    rewardsURL, surveysURL,
    usersListURL, userURL
} from '../Utils/urls';

let tokenValueLogin: any = null;

export class AuthAPI {
    static async login(
        username: string,
        password: string,
        registerType: string,
        language: string
    ): Promise<any> {
        return api.post(loginURL, { email: username, password, registerType, language, loginType:"web"})
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }


    static async profile(
        userId: string,
    ): Promise<any> {
        return api.get(`${getProfile}/${userId}`)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }


    static async uploadProfile(
        body: any
    ): Promise<any> {
        return api.post(`${userURL}/uploadProfile`, body)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }


    static async registration(
        body: any,
    ): Promise<any> {
        body.loginType = "web";
        const header = {
            Authorization: `${tokenValueLogin}`,
        };
        return api.post(registrationURL, body, { headers: header })
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }


    static async continueWithMobile(
        data: any
    ): Promise<any> {
        data.loginType = "web";
        return api.post(`${userURL}/continueWithMobile`, data)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static async resendOtp(
        data: any
    ): Promise<any> {
        return api.post(`${userURL}/resendOtp`, data)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static async verifyMobileOtp(
        data: any
    ): Promise<any> {
        return api.post(`${userURL}/verify-mobile`, data)
            .then((res) => {
                return _.get(res, 'data.data', {});
            })
            .catch((error) => {
                console.error('Error in verify Mobile Otp:', error);
                throw error;
            });
    }
   
    static async sentOTPForapproval(
        data: any
    ): Promise<any> {
        return api.post(`${redemptionRespondentRequestURL}/sentOTPForapproval`, data)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static async verifyOTPForApproval(
        data: any
    ): Promise<any> {
        return api.post(`${redemptionRespondentRequestURL}/verifyOTPForApproval`, data)
            .then((res) => {
                return _.get(res, 'data.data', {});
            })
            .catch((error) => {
                console.error('Error in verify Mobile Otp:', error);
                throw error;
            });
    }

    static async createBasicProfile(
        body: any,
        userId: any,
        language: any,
    ): Promise<any> {
        return api.put(`${createBasicProfileURL}/${userId}?language=${language}`, body)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static async redemptionList(
        limit: any,
        currentPageNumber: string,
        filters: any,
    ): Promise<any> {
        return api.post(`${redemptionRequest}/${limit}/${currentPageNumber}`, filters)
            .then((res) => {
                return _.get(res, 'data', {});
            });
    }

    static async approveRedemptionRequest(
        data: any,
    ): Promise<any> {
        return api.post(`${redemptionRespondentRequestURL}/approveRequest`, data)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static async manualApprovedBulk(
        data: any,
    ): Promise<any> {
        return api.post(`${redemptionRespondentRequestURL}/manualBulkApprove`, data)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static async xoxoApprovedBulk(
        data: any,
    ): Promise<any> {
        return api.post(`${redemptionRespondentRequestURL}/xoxoBulkApprove`, data)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static async RejectRequestBulk(
        data: any,
    ): Promise<any> {
        return api.post(`${redemptionRespondentRequestURL}/RejectRequestBulk`, data)
            .then((res) => {
                return _.get(res, 'data', {});
            });
    }

    static async rejectRedemptionRequest(
        data: any,
    ): Promise<any> {
        return api.post(`${redemptionRespondentRequestURL}/rejectRequest`, data)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    // static async rewardsList(
    //     limit : number,
    //     pagenumber: number,
    // ): Promise<any> {
    //     return api.post(`${rewardsURL}/${limit}/${pagenumber}`)
    //         .then((res) => {
    //             return _.get(res, 'data', []);
    //         });
    // }
    static async rewardsList(
        limit: number,
        pageNumber: number,
        filters: any = {}
    ): Promise<any> {
        const payload = {
            "rewardfrom": filters.rewardfrom,
            "rewardto": filters.rewardto,
            "rewardType": filters.rewardType,
        };

        try {
            const res = await api.post(`${rewardsURL}/${limit}/${pageNumber}`, payload);
            return _.get(res, 'data', []);
        } catch (error) {
            console.error("Failed to fetch rewards:", error);
            return { data: [], totalCount: 0, totalPage: 0 };
        }
    }

    static async rewardsListexport(): Promise<any> {
        try {
            const res = await api.post(`${rewardsURL}/All/1`);
            return _.get(res, 'data.data', {});
        } catch (error) {
            console.error("Error fetching rewards list:", error);
            throw error;
        }
    }

    static getAllByUserId(
        limit = 100000,
        userId,
    ): Promise<any[]> {
        return api.get(`${rewardsAPIURL}/getAllByUserId/${userId}/${limit}`, {
        }).then((res) => {
            return _.get(res, 'data.data', []);
        });
    }

    static ResetPasswordLink(email, language): Promise<any> {
        return api.post(`${userURL}/reset-password?language=${language || 'en'}`, { email: email })
            .then((res) => {
                return _.get(res, 'data.message', {});
            });
    }

    static UpdateNewPassword(token, password): Promise<any> {
        return api.post(`${userURL}/Updatenew-password/${token}`, { password: password })
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static ChangePassword(value): Promise<any> {
        return api.post(`${userURL}/change-password`, value)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static UnSubscribeUser(userId): Promise<any> {
        return api.post(`${userURL}/unSubscribeUser/${userId}`, {})
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static requestForDelete(userId): Promise<any> {
        return api.post(`${userURL}/permanentlyDelete/${userId}/user`, {})
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static userNotifications(userId): Promise<any> {
        return api.get(`${userURL}/userNotifications/${userId}`)
            .then((res) => {
                console.log(res, "notification view ");

                return _.get(res, 'data.data', []);
            });
    }
    static appInbox(userId): Promise<any> {
        return api.get(`${userURL}/userNotifications/${userId}`)
            .then((res) => {
                console.log(res, "appInbox view ");

                return _.get(res, 'data.data', []);
            });
    }

    static deleteActions(userId, action): Promise<any> {
        return api.put(`${userURL}/deleteActions/${userId}/${action}`, {})
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static async usersList(
        limit: any,
        pagenumber: number,
        filters: any,
        type: string,
    ): Promise<any> {
        return api.post(`${usersListURL}/list/${limit}/${pagenumber}/${type}`, filters)
            .then((res) => {
                return _.get(res, 'data', []);
            });
    }

    static async allPanelists(
        body: any,
        limit: any,
        currentPageNumber: number
    ): Promise<any> {
        return api.post(`${usersListURL}/allPanelist/${limit}/${currentPageNumber}`, body)
            .then((res) => {
                return _.get(res, 'data', {});
            });
    }


    //Referrals
    static async referralsList(
        limit: any,
        currentPageNumber: number,
        filters: any,
    ): Promise<any> {
        return api.post(`${referralsUrl}/getAll/${limit}/${currentPageNumber}`, filters)
            .then((res) => {
                return _.get(res, 'data', {});
            });
    }


    static async createReferralRequest(
        body: any,
        language: any,
    ): Promise<any> {
        return api.post(`${referralsUrl}/create?language=${language}`, body)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }


    static async createBulkReferralRequest(
        body: any,
        language: any,
    ): Promise<any> {
        return api.post(`${referralsUrl}/bulkCreateReferrals?language=${language}`, body)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static async referralsListByUserId(
        limit: number,
        userId: string
    ): Promise<any> {
        return api.get(`${referralsUrl}/getAllUserReferrals/${userId}/${limit}`)
            .then((res) => {
                return _.get(res, 'data.data', []);
            });
    }

    static async NewcontinueWithMobile(
        data: any
    ): Promise<any> {
        return api.post(`${userURL}/verifyIntity`, data)
            .then((res) => {
                return res.data;
                // return _.get(res, 'data.data', {});
            });
    }


    static async newverifyMobile(
        data: any
    ): Promise<any> {
        return api.post(`${userURL}/verifyIntityOtp`, data)
            .then((res) => {
                tokenValueLogin = res.data.message.data;
                return res.data;
            });
    }

    static async ApprovePendingfinance(
        data: any,
    ): Promise<any> {
        return api.post(`${redemptionRespondentRequestURL}/ApprovePendingfinance`, data)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static async manualBulkApproveAdmin(
        data: any,
    ): Promise<any> {
        return api.post(`${redemptionRespondentRequestURL}/manualBulkApproveAdmin`, data)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }


    static async xoxoBulkApproveadmin(
        data: any,
    ): Promise<any> {
        return api.post(`${redemptionRespondentRequestURL}/xoxoBulkApproveadmin`, data)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static async addPreScreenQuestions(
        data: any,
    ): Promise<any> {
        return api.post(`${redemptionRespondentRequestURL}/Survey-Questions`, data)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static async updatePreScreenQuestions(
        data: any,
    ): Promise<any> {
        return api.post(`${redemptionRespondentRequestURL}/updateSurveyQuestion`, data)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static async getSurveyQuestions(
        data:any
    ): Promise<any> {
        return api.post(`${redemptionRespondentRequestURL}/getSurveyQuestions`, data)
            .then((res) => {
                console.log("res = ",res);
                
                return _.get(res, 'data.data', {});
            });
    }

    static async matchAnswers(
        data:any
    ): Promise<any> {
        return api.post(`${redemptionRespondentRequestURL}/matchAnswers`,data)
        .then((res) => {
            return _.get(res, 'data.data',{});
        });
    }

    static async getstatusFinance(
        data:any
    ): Promise<any> {
        return api.post(`${redemptionRespondentRequestURL}/getstatusFinance`, data)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static async BothmanualBulkApprove(
        data:any
    ): Promise<any> {
        return api.post(`${redemptionRespondentRequestURL}/BothmanualBulkApprove`, data)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static async allInactiveUsers(
        limit: any,
        pagenumber: number,
        filters: any,
    ): Promise<any> {
        return await api.post(`${userURL}/allInactiveUsers/${limit}/${pagenumber}`, filters)
            .then((res) => {
                return _.get(res, 'data', {});
            });
    }

    static async getAllQuestions(
        data:any
    ): Promise<any> {
        return api.get(`${redemptionRespondentRequestURL}/getAllQuestions`, data)
            .then((res) => {
                console.log("res = ",res);
                return _.get(res, 'data.data', {});
            });
    }

    static async SurveyQuestions(
        data:any
    ): Promise<any> {
        return api.post(`${redemptionRespondentRequestURL}/Survey-Questions`, data)
            .then((res) => {
                console.log("res = ",res);
                return _.get(res, 'data.data', {});
            });
    }

    static async updateSurveyQuestions(
        data:any
    ): Promise<any> {
        return api.post(`${redemptionRespondentRequestURL}/updateSurveyQuestions`, data)
            .then((res) => {
                console.log("res = ",res);
                return _.get(res, 'data.data', {});
            });
    }

    static async updateSurveyquestionanswer(
        data:any
    ): Promise<any> {
        return api.post(`${redemptionRespondentRequestURL}/updateSurveyquestionanswer`, data)
            .then((res) => {
                console.log("res = ",res);
                return _.get(res, 'data.data', {});
            });
    }

    static async getAnswersOfQuestion(
        data:any
    ): Promise<any> {
        return api.post(`${redemptionRespondentRequestURL}/getAnswersOfQuestion`, data)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static async deleteQuestion(
        data:any
    ): Promise<any> {
        return api.post(`${redemptionRespondentRequestURL}/deleteQuestion`, data)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

}
