import React, {useState, useEffect} from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Typography, Container, Paper } from '@material-ui/core';
import { Assets } from 'enums';
import { Alert, Modal, Button, Spinner, Table, } from 'react-bootstrap';
import { PageStatus } from 'enums';
import { Show } from 'Layout';
import * as _ from "lodash";
import ShowDataAccording2PageSize from '../ShowEntries/ShowEntries';
import PaginationNumbering from '../ShowEntries/Pagination';
import {AuthAPI, RedemptionModeAPI} from "../../API";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from "react-redux";
import GridContainer from "../Grid/GridContainer";
import CardHeader from "../Card/CardHeader";
import moment from "moment/moment";
import {Confirmation} from "../../Shared/Confirmation";

function PreScreen() {

  const { userId, role } = useSelector((state: any) => state.adminUser.adminUser);

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    appBar: {
      marginBottom: theme.spacing(3),
    },
    content: {
      padding: theme.spacing(3),
      textAlign: 'left',
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
      alignItems: 'center',
    },
    logo: {
      width: '100%',
      maxWidth: '400px', // Adjust the maximum width as needed
    },
    grow: {
      flexGrow: 1,
    },
  }));

  const MODAL_TYPES = {
    NONE: 'NONE',
    CREATE: 'CREATE',
    UPDATE: 'UPDATE',
    DELETE: 'DELETE',
    DETAILS: 'DETAILS',
    QUESTION: 'QUESTION'
  };

  const [formType, setFormType] = useState(MODAL_TYPES.NONE);
  const [status, setStatus] = useState(PageStatus.None);
  const [error, setError] = useState(null);
  const [formState, setFormState] = useState({});
  const [preScreenApiData, setPreScreenApiData] = useState([]);
  const [pageSize, setpageSize] = useState(10);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [totalResponseData, setTotalResponseData] = useState('');
  const [totalResponsePages, setTotalResponsePages] = useState('');
  const [questionName, setQuestionName] = useState('');
  const [modalHeader, setModalHeader] = useState("Add");
  const [id, setId] = useState('');
  const [answersFields, setAnswersFields] = useState([]);
  const [updateAnsVal, setUpdateAnsVal] = useState(false);
  const [isActiveTab, setActiveTab] = useState(false);
  const [origionalApiResponse, setOrigionalApiResponse] = useState([]);
  const [selectedAnswer, setSelectedAnswer] = useState([]);

  const handleOpenCreateModal = () => { setFormType(MODAL_TYPES.CREATE); setModalHeader("Add"); setId(''); };

  const fetchList = async () => {
    setStatus(PageStatus.Loading);
    try {
      const responseData: any = await AuthAPI.getAllQuestions({});
      const responseSortedData = isActiveTab ? responseData.filter((ele=> ele.type != "new")) : responseData.filter((ele=> ele.type != "auto"));
      setPreScreenApiData(responseSortedData);
      setOrigionalApiResponse(responseSortedData);
      // setTotalResponseData(responseData.totalCount);
      // setTotalResponsePages(responseData.totalPage);

      setStatus(PageStatus.Loaded);
    } catch (err) {
      setStatus(PageStatus.Loaded);
      setError("Failed, Try again!");
    }
  };

  const onCloseModalFunc = () => { setFormType(MODAL_TYPES.NONE); setFormState({}); setError(null); setAnswersFields([]); setStatus(PageStatus.None); setUpdateAnsVal(false); setSelectedAnswer([]); };

  const onFormSubmit = async (event) => {
    event.preventDefault();
    try {
      let submitingData = { ...formState, answer: answersFields };
      let apiRespo: any;
      if (id) {
        apiRespo = await AuthAPI.updateSurveyQuestions({ id, ...submitingData });
      } else {
        apiRespo = await AuthAPI.SurveyQuestions(submitingData);
      }
      setStatus(PageStatus.Submitting);
      const responseData: any = apiRespo;
      if(responseData.status == 0){ alert(responseData.message); }
      setStatus(PageStatus.Submitted);
      setFormType(MODAL_TYPES.NONE);
      setFormState({});
      setAnswersFields([]);
      fetchList();
    } catch (err) {
      setError("Failed, Try again!");
      setStatus(PageStatus.Error);
    }
  }

  const onFormUpdate = async (event) => {
    try {
      event.preventDefault();
      if(selectedAnswer.length < 1){
        alert("Please choose answer first");
        return;
      }
      let answerSettingPayload = {"questionId" : id, "answerID": selectedAnswer};
      const ansRespoData: any = await AuthAPI.updateSurveyquestionanswer(answerSettingPayload);
      alert("Answer updated successfully.");
      onCloseModalFunc();
      fetchList();  
    } catch (err) {
      setError("Failed, Try again!");
      setStatus(PageStatus.Error);
    }
  }

  const handleChange = (e) => {
    let { name, value } = e.target;
    setFormState((prev) => ({
      ...prev, [name]: value
    }));
    if (name !== "question") {
      if (name == "questionType" && value == "Range") {
        setAnswersFields([{ "lable": "Select Value", "name": "Range", "fromValue": "", "toValue": "" }]);
      } else {
        setAnswersFields([{ "lable": value, "name": value, "value": "" }]);
      }
    }
  };

  const addMoreRow = (name, indx, type) => {
    if (type == "add") {
      if (!answersFields[indx].value && name != "Select Value") {
        alert("please enter answer.");
        return;
      }
      if ((!answersFields[indx].toValue || !answersFields[indx].fromValue) && name == "Select Value") {
        alert("please enter range values.");
        return;
      }
      let newObj = name != "Select Value" ? { "lable": name, "name": name, "value": "" } : { "lable": "Select Value", "name": "Range", "fromValue": "", "toValue": "" };
      setAnswersFields((prevVal) => [
        ...prevVal,
        newObj
      ]);
    } else {
      setAnswersFields((prevVal) => {
        let updatedAnswers = [...prevVal];
        let newArr = updatedAnswers.filter((e, i) => i !== indx);
        return newArr;
      });
    }
  }

  const setInputValueInArr = (lable, evnt, indx, field) => {
    setAnswersFields((prevVal) => {
      let updatedAnswers = [...prevVal];
      let newArr = updatedAnswers.map((e, i) => {
          if (i == indx) {
            return { ...e, [field]: evnt.target.value };
          } else {
            return e;
          }
        });
      return newArr;
    });
  };

  const checkValidationNumber = (evntVal, indxx, fieldName)=>{
    setAnswersFields((prevVal)=>{
      let updatedAnswers = [...prevVal];
      if ((answersFields[indxx].fromValue != "") && (answersFields[indxx].toValue != "") && (parseInt(answersFields[indxx].fromValue) >= parseInt(answersFields[indxx].toValue))) {
        alert("'From' value can't be equal or greater than 'To' value.");
          updatedAnswers[indxx][fieldName] = "";
      }
      if((answersFields[indxx][fieldName] != "") && answersFields.length > 1){
          for (let elemIndxx = 0; elemIndxx < answersFields.length; elemIndxx++) {
            if(elemIndxx != indxx && ( parseInt(answersFields[indxx][fieldName]) <= parseInt(answersFields[elemIndxx].toValue) && parseInt(answersFields[indxx][fieldName]) >= parseInt(answersFields[elemIndxx].fromValue))){
              alert(`${updatedAnswers[indxx][fieldName]} is included in row number ${elemIndxx+1}.`);
              updatedAnswers[indxx][fieldName] = "";
              break;
            }
            if((elemIndxx != indxx) && (answersFields[indxx].fromValue != "") && (answersFields[indxx].toValue != "") && (( parseInt(answersFields[indxx].fromValue) < parseInt(answersFields[elemIndxx].toValue)) && (parseInt(answersFields[indxx].fromValue) < parseInt(answersFields[elemIndxx].fromValue))) && (( parseInt(answersFields[indxx].toValue) > parseInt(answersFields[elemIndxx].toValue)) && (parseInt(answersFields[indxx].toValue) > parseInt(answersFields[elemIndxx].fromValue)))){
              alert(`Row number ${elemIndxx+1} is included in current range.`);
              updatedAnswers[indxx][fieldName] = "";
              break;
            }
        }
      }
      return updatedAnswers;
    });
  }

  const editPreScreenQuestionDetails = (e, elem) => {
    e.stopPropagation();
    setId(elem.id);
    setFormType(MODAL_TYPES.CREATE);
    setModalHeader("Update");
    let answersValue = elem.questionType != "Range" ? elem.answer.map((e)=>{ return {"lable": elem.questionType, "name": elem.questionType, "value": e} }) : elem.answer.map((e)=>{ return { "lable": "Select Value", "name": "Range", "fromValue": e.fromValue, "toValue": e.toValue } });
    setFormState({ "question": elem.question,"questionType":elem.questionType, "answer": answersValue, "selectedAnsArr":[], });
    setAnswersFields(answersValue);
  }

  const deletePreScreenQuestionDetails = async (e, elem) => {
    try {
      const ansRespoData: any = await AuthAPI.deleteQuestion({"id": elem.id});
      alert("Question deleted successfully");
      fetchList();
    } catch (error) {
      setError("Failed, Try again!");
      setStatus(PageStatus.Error);
    }
  }

  // const updatePreScreenAnswer = async (e, elem) => {
  //   let getPreScreenQueDetails = await AuthAPI.getAnswersOfQuestion({"id":elem.id});
  //   setId(elem.id);
  //   let answersValue = elem.answer.map((e)=>{ return {"lable": elem.questionType, "name": elem.questionType, "value": e} });
  //   setFormState({ "question": elem.question,"questionType":elem.questionType, "answer": answersValue, "selectedAnsArr": getPreScreenQueDetails.answer, });
  //   setUpdateAnsVal(true);
  // }

  const handleAnswerChange = (event, ansVal, type) => {
    setSelectedAnswer((prevSelectedAnswer) => {
      if(type == "checkbox"){
        let newArr = event.target.checked ? [...prevSelectedAnswer, ansVal] : [...prevSelectedAnswer].filter(e=> e!=ansVal);
        return newArr;
      }else if(type == "Range"){
        let newArr = [...prevSelectedAnswer, ansVal].filter(e=> e==ansVal && e!="");
        return newArr;
      }else{
        let newArr = [...prevSelectedAnswer, ansVal].filter(e=> e==ansVal && e!="");
        return newArr;
      }
    });
  };

  const handleTabSwitchFunc = (type)=>{
    type == "profile" ? setActiveTab(true) : setActiveTab(false);
    setQuestionName("");
  }

  useEffect(() => {
    if(updateAnsVal == true){ setFormType(MODAL_TYPES.UPDATE); }
  }, [updateAnsVal]);

  // useEffect(() => {  fetchList(); }, [pageSize, currentPageNumber]);
  useEffect(() => { fetchList(); }, [isActiveTab]);

  const setQuestionNameFunc = (evt)=>{ setQuestionName(evt.target.value.trim()); }

  const clearFilters = ()=>{ setQuestionName((prevVal)=>{ fetchList(); return "" }); }

  useEffect(()=>{
    if (preScreenApiData.length && questionName) {
      let newArr = [...preScreenApiData];
      newArr = newArr.filter((ele) => ele.question.toLowerCase().includes(questionName.toLowerCase()) );
      setPreScreenApiData(newArr);
    }
  },[origionalApiResponse])

  // const handlePageSizeChange = (newValue: any) => { setpageSize(newValue); setCurrentPageNumber(1); setTotalResponsePages(''); };
  // const handlePageNumberChange = (newValue: any) => { setCurrentPageNumber(newValue); };

  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <Modal
          centered
          size="lg"
          backdrop="static"
          onHide={onCloseModalFunc}
          show={formType == MODAL_TYPES.CREATE}
          style={{ zIndex: 1201 }}
        >
          <Modal.Header closeButton>
            <Modal.Title>  {modalHeader} PreScreen Question </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ maxHeight: '78vh', overflow: 'auto' }}>
            <Show when={status === PageStatus.Loading}>
              <div className="d-flex justify-content-center w-100 p-5">
                <Spinner animation="border" variant="primary" />
              </div>
            </Show>
            <Alert variant="danger" show={status === PageStatus.Error}>  {error}  </Alert>
            <form onSubmit={onFormSubmit} >
              <div className="row mt-2">
                <div className="col">
                  <label htmlFor="titleEng">  Question*  </label>
                  <input
                    className="form-control"
                    name="question"
                    onChange={handleChange}
                    value={formState.question || ""}
                    placeholder="Enter question"
                    required
                  />
                </div>
                <div className="col">
                  <label htmlFor="titleEng">  Question Field Type*  </label>
                  <select
                    className="form-control"
                    name="questionType"
                    onChange={handleChange}
                    value={formState.questionType || ""}
                    disabled={id != ""}
                    required
                  >
                    <option value="">Select type</option>
                    <option value="Range">Range</option>
                    {/* <option value="Zone">Zone</option> */}
                    {/* <option value="Zipcode">ZipCode</option> */}
                    <option value="Dropdown">Dropdown</option>
                    <option value="Radio">Radio</option>
                    <option value="Checkbox">CheckBox</option>
                    <option value="MultiSelectDropdown">MultiSelectDropdown</option>
                  </select>
                </div>
              </div>

              {answersFields && answersFields.map((ele, index) => (
                ele.name == "Range" ? (<div className="col p-0" key={`abcd${index}`}>
                  {/* <div className="col"> */}
                    <label htmlFor="body"></label>
                    <div className="input-group">
                        <input
                          className="form-control mx-1"
                          name={ele.name}
                          onChange={(e) => setInputValueInArr(ele.lable, e, index, "fromValue")}
                          onBlur={(e)=> checkValidationNumber(e, index, "fromValue")}
                          value={ele.fromValue}
                          type='number'
                          placeholder={`From`}
                          required
                        />
                        <input
                          className="form-control mx-1"
                          name={ele.name}
                          onChange={(e) => setInputValueInArr(ele.lable, e, index, "toValue")}
                          onBlur={(e)=> checkValidationNumber(e, index, "toValue")}
                          value={ele.toValue}
                          type='number'
                          placeholder={`To`}
                          required
                        />
                        {answersFields.length > 0 && index == answersFields.length-1 ? (
                          <em
                          onClick={(e) => addMoreRow(ele.lable, index, "add")}
                          className="fa fa-plus text-success fa-lg cursor-pointer input-group-text"
                          title="Add more"
                          />
                        ) : null}
                        {answersFields.length > 1 ? (
                          <em
                            onClick={() => addMoreRow(ele.lable, index, "dlt")}
                            className="fa fa-trash text-danger fa-lg cursor-pointer input-group-text"
                            title="Delete row"
                          />
                        ) : null}
                    </div>
                  {/* </div> */}
                </div>) :
                  (
                    <div className="col p-0" key={`abcd${index}`}>
                      <label htmlFor="body">Answer {answersFields.length > 1 ? index + 1 : ""}*</label>
                      <div className="input-group">
                        <input
                          className="form-control"
                          type="text"
                          value={ele.value}
                          name=""
                          id=""
                          onChange={(e) => setInputValueInArr(ele.lable, e, index, "value")}
                          placeholder={`Enter answer ${answersFields.length > 1 ? index + 1 : ""}`}
                          required
                        />
                        {answersFields.length > 0 && index == answersFields.length-1 ? (
                        <em
                          onClick={(e) => addMoreRow(ele.lable, index, "add")}
                          className="fa fa-plus text-success fa-lg cursor-pointer input-group-text"
                          title="Add more"
                        />
                        ) : null}
                        {answersFields.length > 1 ? (
                          <em
                            onClick={() => addMoreRow(ele.lable, index, "dlt")}
                            className="fa fa-trash text-danger fa-lg cursor-pointer input-group-text"
                            title="Delete row"
                          />
                        ) : null}
                      </div>

                    </div>
                  )
              ))}

              <Alert variant="danger" show={status === PageStatus.Error} className="mt-2">
                {error}
              </Alert>

              <div className="d-flex align-items-center mt-2">
                <button
                  type="submit"
                  className="btn btn-primary mr-3"
                >
                  Submit
                </button>

                <Show when={status === PageStatus.Submitting}>
                  <Spinner animation="border" variant="primary" />
                </Show>
              </div>
            </form>
          </Modal.Body>
        </Modal>

        <Modal
          centered
          size="lg"
          backdrop="static"
          onHide={onCloseModalFunc}
          show={formType == MODAL_TYPES.UPDATE}
          style={{ zIndex: 1201 }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add PreScreen Answer </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ maxHeight: '78vh', overflow: 'auto' }}>
            <Show when={status === PageStatus.Loading}>
              <div className="d-flex justify-content-center w-100 p-5">
                <Spinner animation="border" variant="primary" />
              </div>
            </Show>
            <Alert variant="danger" show={status === PageStatus.Error}>  {error}  </Alert>
            {formState?.question && (
                <form onSubmit={onFormUpdate}>
                  <div className="row mt-2">
                    <div className="col">
                      <lable>{formState?.question}</lable>
                      {formState?.answer?.map((e, index) => (
                        e.name === "Radio" ? (
                          <div className="custom-control custom-radio" key={index}>
                          <input
                            type="radio"
                            id={`prescreen-${index}`}
                            name={`prescreen-${index}`}
                            checked={selectedAnswer?.includes(e.value) || formState?.selectedAnsArr?.includes(e.value)}
                            className="custom-control-input"
                            onChange={(evt) => handleAnswerChange(evt, e.value, "radio")}
                          />
                          <label className="custom-control-label" htmlFor={`prescreen-${index}`}><span className='text-lg'>{e.value}</span></label>
                        </div>
                        ) : (e.name === "Checkbox" || e.name === "checkbox") ? (
                          <div className="col ml-2" key={index}>
                            <input
                              type="checkbox"
                              id={`prescreen-${index}`}
                              name={`prescreen-${index}`}
                              checked={selectedAnswer?.includes(e.value) || formState?.selectedAnsArr?.includes(e.value)}
                              className="custom-control-input"
                              onChange={(evt) => handleAnswerChange(evt, e.value, "checkbox")}
                            />
                            <label className="custom-control-label" htmlFor={`prescreen-${index}`}><span className='text-lg'>{e.value}</span></label>
                          </div>
                        ) : null
                      ))}
                      
                      {
                       (formState?.questionType == "Range" || formState?.questionType == "Dropdown") && <div>
                        <select 
                          name="rangeSelect" 
                          id="rangeSelect" 
                          className='form-control'
                          // value={(selectedAnswer.length > 0 ? selectedAnswer[0] : "")}
                          onChange={(evt) => handleAnswerChange(evt, evt.target.value, "Dropdown")}
                          >
                          <option value="">Select option</option>
                          {formState?.answer?.map((e, index) => (
                            <option selected={(selectedAnswer.length > 0 ? selectedAnswer[0] : "") || formState?.selectedAnsArr?.includes(e.value)} value={e.value} key={index}>{e.value}</option>
                          ))}
                        </select>
                       </div>
                      }
                    </div>
                  </div>

                  {/* Error message */}
                  <Alert variant="danger" show={status === PageStatus.Error} className="mt-2">
                    {error}
                  </Alert>

                  <div className="d-flex align-items-center mt-2">
                    <button type="submit" className="btn btn-primary mr-3">
                      Submit
                    </button>

                    {/* Show spinner when submitting */}
                    {status === PageStatus.Submitting && (
                      <Spinner animation="border" variant="primary" />
                    )}
                  </div>
                </form>
              )}

          </Modal.Body>
        </Modal>

        <GridContainer>
          <div className='w-100 mb-3'>
            <CardHeader color="primary">
              <div className="d-flex align-items-center justify-content-between">
                <h4>PreScreen Questions</h4>
                <div>
                  <Button
                    onClick={handleOpenCreateModal}
                    variant="primary"
                    size="sm"
                    className="mx-1"
                  >
                    <FontAwesomeIcon icon={['fas', 'plus']} className="mr-2" />
                    Create
                  </Button>
                </div>
              </div>
            </CardHeader>
          </div>
        </GridContainer>
        <div className="jumbotron bg-white p-3 border shadow-sm">
          <div className='mb-3'>Filter</div>
          <div className="row">
            <div className="col">
              <label>Name</label>
              <input
                  type="text"
                  className="form-control"
                  placeholder="Search Question"
                  name="questionName"
                  value={questionName}
                  onInput={(e)=> {setQuestionNameFunc(e)}}
              />
            </div>
          </div>
          
          <div className="jumbotron bg-white p-1 mt-2 shadow-sm">
              <button type="button" className="btn btn-success" onClick={() => fetchList()}>Filter Questions</button>
              {/* <button type="button" className="btn btn-info ml-1" onClick={() => handleExport()}>Export</button> */}
              <button type="button" className="btn btn-danger ml-1" onClick={() => clearFilters()}>Clear Filter</button>
            </div>
        </div>
        <div className="jumbotron bg-white p-3 border shadow-sm">
          {/* <div className='mb-2 mt-3'>
            <ShowDataAccording2PageSize
              currentPageSize={pageSize}
              currentPage={currentPageNumber}
              sendPageSize={handlePageSizeChange}
              showTotalData={totalResponseData}
              sendLastPage={totalResponsePages}
            />
          </div> */}
          {role && role == "admin" && preScreenApiData.length > 0 ? (<div className='mb-3'>
            <ul className="nav nav-pills nav-fill">
              <li className="nav-item">
                <a className={`nav-link ${isActiveTab ? "active" : ""}`} aria-current="page" href="javascript:;" onClick={()=>{handleTabSwitchFunc("profile")}}>Profile Questions</a>
              </li>
              <li className="nav-item">
                <a className={`nav-link ${!isActiveTab ? "active" : ""}`} href="javascript:;" onClick={()=>{handleTabSwitchFunc("custom")}}>Custom Questions</a>
              </li>
            </ul>
          </div>) : ""}
          <div>
            <Show when={status === PageStatus.Loading}>
              <div className="d-flex justify-content-center w-100 p-5">
                <Spinner animation="border" variant="primary" />
              </div>
            </Show>
            <Show when={!preScreenApiData.length}>
              <Alert variant="info" show={!preScreenApiData.length}>
              No data is available
              </Alert>
            </Show>
            <Show when={status !== PageStatus.Loading && preScreenApiData.length > 0}>
              <Table responsive size="sm" bordered>
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Question</th>
                    {!isActiveTab ? (<th>Question Type</th>) : ""}
                    {/* <th>Answer Selected</th> */}
                    <th>CreatedAt</th>
                    <th>UpdatedAt</th>
                    {/* <th>Action</th> */}
                    {role && role == "admin" && !isActiveTab ? (<th>Action</th>) : ""}
                  </tr>
                </thead>
                <tbody>
                  {
                    preScreenApiData.map((preScreen, index) => (
                      <tr key={preScreen.id}>
                        <td>{((pageSize) * (currentPageNumber - 1)) + (index + 1)}</td>
                        <td>
                          <span
                            // aria-label="button"
                            // role="button"
                            tabIndex={0}
                            className="text-primary"
                            onKeyPress={() => null}
                            // onClick={ (event) => updatePreScreenAnswer(event, preScreen) }
                            dangerouslySetInnerHTML={{
                              __html: preScreen.question || 'Title',
                            }}
                          />
                        </td>
                        {/* <td>{preScreen.answerYN == "Y" ? "Yes" : "No"}</td> */}
                        {!isActiveTab ? (<td>{preScreen.questionType}</td>) : ""}
                        <td>{moment(preScreen.createdAt).format('MM/DD/YYYY HH:mm A')}</td>
                        <td>{moment(preScreen.updatedAt).format('MM/DD/YYYY HH:mm A')}</td>
                        {role && role == "admin" && !isActiveTab ? (<td>
                          <div className='d-flex text-center'>
                          {role && preScreen.type != "auto" && (role == "admin" || role == "sub-admin" || role == "pm") ? (
                              <div className='mx-1'>
                                <span
                                  aria-label="button"
                                  role="button"
                                  tabIndex={0}
                                >
                                  <i
                                    className='fa fa-edit text-success'
                                    onClick={(event) => editPreScreenQuestionDetails(event, preScreen)}
                                    title="Edit details"
                                  ></i>
                                </span>
                              </div>
                          ) : "-"}

                          {role && preScreen.type != "auto" && (role == "admin") ? (
                            <div className='mx-1'>
                                <span
                                  aria-label="button"
                                  role="button"
                                  tabIndex={0}
                                >
                                <Confirmation onAction={(event) => deletePreScreenQuestionDetails(event, preScreen)} body="Do you want to delete this question ?">
                                  <i
                                    className='fa fa-trash text-danger'
                                    title="Delete question"
                                  ></i>
                                </Confirmation>
                                </span>
                              </div>
                            ) : ""}
                          </div>
                        </td>):""}
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
            </Show>
          </div>
          {/* <div>
            {totalResponsePages && <PaginationNumbering
              currentPage={currentPageNumber}
              returnPagenumber={handlePageNumberChange}
              showTotalData={totalResponseData}
              sendLastPage={totalResponsePages}
            />}
          </div> */}
        </div>
      </div>
    </>
  );
}

export default PreScreen;
