import { makeStyles, Paper, Typography } from "@material-ui/core";
import { Assets, PageStatus } from "enums";
import { Container } from "@material-ui/core";
import { useEffect, useState } from "react";
import { AuthAPI } from "API";
import { Show } from "Layout";
import { Alert, Spinner } from "react-bootstrap";
import { useLocation } from "react-router";
import Select from 'react-select';

// Define types for API response
interface Question {
    survey_id: string;
    id: number;
    created_at: string;
    updated_at: string;
    deleted_at?: string;
    status: string;
    question: string;
    answer: string;
}

function PreScreenQuesPage() {
    const [status, setStatus] = useState<PageStatus>(PageStatus.None);
    const [preScreenApiData, setPreScreenApiData] = useState<Question[]>([]);
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const [currentQues, setCurrentQues] = useState<Question | null>(null);
    const [selectedAnswer, setSelectedAnswer] = useState<Question[]>([]);
    const [formData, setFormData] = useState<{ question: string; answer: [] }[]>([{ question: "", answer: [] }]);
    const [submitBtnTF, setSubmitBtnTF] = useState(false);
    // const [selectedMultipleAnswers, setSelectedMultipleAnswers] = useState([]);
    const [selectedDropdownAnswers, setSelectedDropdownAnswers] = useState([]);
    const location = useLocation();

    const useStyles = makeStyles((theme) => ({
        root: { flexGrow: 1, },
        appBar: { marginBottom: theme.spacing(3), },
        content: { padding: theme.spacing(3), textAlign: 'left', },
        paper: { padding: theme.spacing(2), display: 'flex', overflow: 'auto', flexDirection: 'column', alignItems: 'center', },
        logo: { width: '100%', maxWidth: '400px', },
        grow: { flexGrow: 1, },
        btn: {
            padding: "10px 20px", color: "black", backgroundColor: "linear-gradient(#e7e7e7, #f3f3f3)", borderRadius: "10px", border: "1px solid #8f8f8f", margin: "1rem",
            '&:hover': { backgroundColor: "#008083 !important", color: "#ffffff", transform: "scale(1.05)", },
        },
        progress: {
            background: "rgba(0,0,0,.2)", borderRadius: "5px", width: "30%", height: "1rem",
            [theme.breakpoints.down('sm')]: { width: "80%", },
            [theme.breakpoints.up('sm')]: { width: "30%", },
        },
        footer: { bottom: 10, right: 10, display: 'flex', position: "fixed", padding: "10px" },
    }));

    const preScreenfetchList = async () => {
        setStatus(PageStatus.Loading);
        const data = { survey_id: location.pathname.split("/").at(-2) };
        try {
            const responseQueData: Question[] = await AuthAPI.getSurveyQuestions(data);
            const responseData = responseQueData.sort((a,b)=> {return a.questionDisplayOrder - b.questionDisplayOrder});
            setPreScreenApiData(responseData);
            setCurrentQues(responseData[0] || null);
            setStatus(PageStatus.Loaded);
        } catch (err) {
            console.error("Failed to fetch survey questions:", err);
            setStatus(PageStatus.Error);
        }
    };

    const nextButton = () => {
        if (formData[currentIndex].answer.length < 1) { alert("choose answer"); return; }
        if (currentIndex < preScreenApiData.length - 1) {
            setFormData((prevFormData) => {
                if (currentIndex == prevFormData.length - 1 && prevFormData.length < preScreenApiData.length) {
                    return [...prevFormData, { "question": "", "answer": [] }];
                } else { return [...prevFormData]; }
            });
            setCurrentIndex(currentIndex + 1);
            setCurrentQues(preScreenApiData[currentIndex + 1]);
            setSelectedAnswer(formData[currentIndex].answer.length < 1 ? [] : formData[currentIndex].answer);
        }
    };

    const previousButton = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
            setCurrentQues(preScreenApiData[currentIndex - 1]);
        }
    };

    const handleSelectAnswer = (evt, answer, type) => {
        let newArr = [];
        setFormData((prevFormData) => {
            const updatedFormData = [...prevFormData];
            const previousAnswers = updatedFormData[currentIndex] ? updatedFormData[currentIndex].answer : [];
            if (type == "checkbox") {
                newArr = evt.target.checked ? [...previousAnswers, answer] : [...previousAnswers].filter(e => e != answer);
            } else {
                newArr = [...previousAnswers, answer].filter(e => e == answer && e != "");
            }
            updatedFormData[currentIndex] = { "question": currentQues?.id, "answer": newArr };
            setSelectedAnswer(newArr);
            return updatedFormData;
        });
    }

    // const handleMultiSelectAnswers = (selectedMultipleAnswers)=>{
    //     setFormData((prevFormData) => {
    //         const updatedFormData = [...prevFormData];
    //         let newArr = selectedMultipleAnswers.map(option => option.value);
    //         updatedFormData[currentIndex] = { "question": currentQues?.id, "answer": newArr };
    //         setSelectedAnswer(newArr);
    //         setSelectedMultipleAnswers(selectedMultipleAnswers);
    //         return updatedFormData;
    //     }); 
    // }

    const handleDropDownSelectAnswers = (selectedOptionVal) => {
        setFormData((prevFormData) => {
            let updatedSelectedAnswers = [...selectedDropdownAnswers];
            let newArr = selectedOptionVal.value;
            const updatedFormData = [...prevFormData];
            updatedFormData[currentIndex] = { "question": currentQues?.id, "answer": [newArr] };
            updatedSelectedAnswers = updatedSelectedAnswers.filter(ele => ele.question !== currentQues?.id);
            updatedSelectedAnswers.push({ "question": currentQues?.id, "selectedDropdownAnswers": selectedOptionVal });
            setSelectedAnswer(newArr);
            setSelectedDropdownAnswers(updatedSelectedAnswers);
            return updatedFormData;
        }); 
    }

    const handleSubmit = async () => {
        if (formData[currentIndex].answer.length < 1) { alert("choose answer"); return; }
        const data = {
            survey_id: location.pathname.split('/').at(-2),
            user_id: location.pathname.split('/').at(-1),
            details: formData
        };
        
        try {
            setSubmitBtnTF(true);
            const response = await AuthAPI.matchAnswers(data);
            window.history.replaceState(null, '', '');
            window.location.href = response;
            // setSelectedMultipleAnswers([]);
            setSelectedAnswer([]);
            setSelectedDropdownAnswers([]);
        } catch (err) {
            setSubmitBtnTF(false);
            alert(err);
            console.error("Submission error:", err);
        }
    };



    const classes = useStyles();

    useEffect(() => { preScreenfetchList(); }, []);

    return (
        <div className={classes.root}>
            <Container maxWidth="lg" className={classes.content}>
                <Paper className={classes.paper}>
                    <img alt="Logo" src={Assets.Logo2} className={classes.logo} />
                </Paper>
                <div className="mt-1">
                    <div
                        className=" w-100"
                        style={{ textAlign: "center", marginTop: "2rem" }}
                    >
                        <div>{`${currentIndex + 1}/${preScreenApiData.length}`}</div>
                        <Typography
                            variant="h4"
                            className="mt-5"
                        >
                            {currentQues ? "" : "Loading question..."}
                        </Typography>
                    </div>
                    <div className="" style={{ display: "grid", placeItems: "center" }}>
                        <div>
                            {currentQues?.question && (
                                <div className="">
                                    <div className="">
                                    <h5>{currentQues ? currentQues.question : ""}</h5>
                                        {
                                            currentQues.questionType === "Radio" ? (
                                                currentQues?.answer?.map((elem, index) => (
                                                    <div className="custom-control custom-radio" key={index}>
                                                        <input
                                                            type="radio"
                                                            id={`prescreen-${index}`}
                                                            name={`prescreen-${index}`}
                                                            checked={formData[currentIndex]?.answer.includes(elem) || false}
                                                            className="custom-control-input"
                                                            onChange={(evt) => handleSelectAnswer(evt, elem, "radio")}
                                                        />
                                                        <label className="custom-control-label" htmlFor={`prescreen-${index}`}><span className='text-lg'>{elem}</span></label>
                                                    </div>
                                                ))
                                            ) : (currentQues.questionType === "Checkbox" || currentQues.questionType === "checkbox") ? (
                                                currentQues?.answer?.map((elem, index) => (
                                                    <div className="col ml-2" key={index}>
                                                        <input
                                                            type="checkbox"
                                                            id={`prescreen-${index}`}
                                                            name={`prescreen-${index}`}
                                                            checked={formData[currentIndex]?.answer.includes(elem) || false}
                                                            className="custom-control-input"
                                                            onChange={(evt) => handleSelectAnswer(evt, elem, "checkbox")}
                                                        />
                                                        <label className="custom-control-label" htmlFor={`prescreen-${index}`}><span className='text-lg'>{elem}</span></label>
                                                    </div>
                                                ))
                                            ) : (currentQues?.questionType == "Range" ) ? (
                                                <div>
                                                    <select
                                                        name="rangeSelect"
                                                        id="rangeSelect"
                                                        className="form-control"
                                                        value={formData[currentIndex]?.answer.length > 0 ? formData[currentIndex]?.answer[0] : ""}
                                                        onChange={(evt) => handleSelectAnswer(evt, evt.target.value, "Range")}
                                                    >
                                                        <option value="">Select option</option>
                                                        {(() => {
                                                            let result = [];
                                                            currentQues?.answer?.forEach(range => {
                                                            const from = parseInt(range.answer.fromValue, 10);  
                                                            const to = parseInt(range.answer.toValue, 10); 
                                                            for (let i = from; i <= to; i++) { result.push(i); };
                                                        });
                                                        let finalResult = Array.from(new Set(result)).sort((a,b)=>{return (a-b)});
                                                        return finalResult.map((value) => (
                                                            <option value={value} key={value+"123"}> {value} </option>
                                                        ));
                                                        })()}
                                                    </select>
                                                </div>
                                            ) : (currentQues?.questionType == "MultiSelectDropdown" ) ? (
                                                <div>
                                                    <Select
                                                        name='MultiSelectDropdown'
                                                        id='MultiSelectDropdown'
                                                        onChange={(evt) => handleDropDownSelectAnswers(evt)}
                                                        // value={selectedMultipleAnswers}
                                                        value={selectedDropdownAnswers.filter((ele)=> ele.question == currentQues?.id)[0]?.selectedDropdownAnswers ?? ""}
                                                        isMulti
                                                        options={currentQues?.answer?.map((ele)=> {return {label:ele, value:ele}})}
                                                    />
                                                </div>
                                            ) :
                                            (currentQues?.questionType == "Dropdown") &&
                                            <div>
                                                <Select
                                                    name='Dropdown'
                                                    id='Dropdown'
                                                    onChange={(evt) => handleDropDownSelectAnswers(evt)}
                                                    value={selectedDropdownAnswers.filter((ele)=> ele.question == currentQues?.id)[0]?.selectedDropdownAnswers ?? ""}
                                                    options={currentQues?.answer?.map((ele)=> {return {label:ele, value:ele}})}
                                                />
                                            </div>
                                        }
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="mt-5" style={{ display: "grid", placeItems: "center", width: "100%" }}>
                        <div className={classes.progress}>
                            <div style={{ background: "#008083", borderRadius: "5px", width: `${(currentIndex + 1) / preScreenApiData.length * 100 + "%"}`, height: "1rem" }}></div>
                            <div style={{ textAlign: "end" }}>{Math.ceil((currentIndex + 1) / (preScreenApiData.length) * 100) + "%"}</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className={classes.footer}>
                        <button
                            type="button"
                            onClick={previousButton}
                            className="btn btn-primary mr-3"
                            disabled={currentIndex === 0}
                        >
                            Previous
                        </button>
                        <button
                            type="button"
                            onClick={currentIndex < preScreenApiData.length - 1 ? nextButton : handleSubmit}
                            className="btn btn-primary mr-3"
                            disabled={submitBtnTF}
                        >
                            {currentIndex < preScreenApiData.length - 1 ? "Next" : "Submit"}
                        </button>
                        {/* <button type="button" className="btn btn-light mr-3">
                            Cancel
                        </button> */}
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default PreScreenQuesPage;