import React, { Component } from 'react';
import {
  Alert, Button, Spinner, Table,
} from 'react-bootstrap';
import { PageStatus } from 'enums';
import {Show} from "../../Layout";
import CardHeader from "../Card/CardHeader";
import GridContainer from "../Grid/GridContainer";
import Card from "../Card/Card";
import moment from "moment/moment";
import {exportToExcel} from "../../Utils/ExportToExcel";
import {AuthAPI, SamplesAPI} from "../../API";
import ShowDataAccording2PageSize from '../ShowEntries/ShowEntries';
import PaginationNumbering from '../ShowEntries/Pagination';


const MODAL_TYPES = {
  NONE: 'NONE',
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  DETAILS: 'DETAILS',
};

type State = {
  status: PageStatus,
  error: string | null,
  formType: string,
  data: any[],
  id?: string | null,
  filteredData: any[],
  filters: {
    firstName: '',
    email: '',
    phoneNumber: ''
  },
  pageSize : any,
  currentPageNumber : any,
  totalResponseData : any,
  totalResponsePages : any,
  loading: any,
};

export class RegisteredOnly extends Component<any, State> {
  constructor(props) {
    super(props);
    this.state = {
      status: PageStatus.None,
      error: null,
      formType: MODAL_TYPES.NONE,
      data: [],
      id: null,
      filteredData: [],
      filters: {
        firstName: '',
        email: '',
        phoneNumber: ''
      },
      pageSize : 10,
      currentPageNumber : 1,
      totalResponseData : '',
      totalResponsePages : '',
      loading: false,
    };
    this.fetchList = this.fetchList.bind(this);
  }

  componentDidMount() {
    this.fetchList()
  }

  fetchList(): Promise<void> {
    const {pageSize, currentPageNumber, filters} = this.state; 
    return Promise.resolve()
      .then(() => this.setState({ status: PageStatus.Loading }))
      .then(() => AuthAPI.usersList(pageSize, currentPageNumber, filters, 'registeredOnly'))
      .then((users) => {
        let responseData: any = users;         
        this.setState({ data: responseData.data, filteredData: responseData.data, totalResponseData: responseData.totalCount, totalResponsePages: responseData.totalPage, status: PageStatus.Loaded });
      })
      .catch((error) => {
        this.setState({ error: error.message, status: PageStatus.Error });
      });
  }

  isShowDetailModal(): boolean {
    return this.state.formType === MODAL_TYPES.DETAILS
        || this.state.formType === MODAL_TYPES.DELETE;
  }


  handleFilterChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value)
    this.setState(
        (prevState) => ({
          filters: {
            ...prevState.filters,
            [name]: value,
          },
        }),
    );
  };

  applyFilters = () => {
    if (this.state.currentPageNumber == 1) { this.fetchList(); return; }
    this.setState({currentPageNumber : 1})
  };

  // applyFilters = () => {
  //   const { data } = this.state;
  //   const { filters } = this.state;
  //     const filteredData = data.filter((user) => {
  //       return Object.keys(filters).every((key) => {
  //         if (filters[key] === '') return true;
  //         if (key === 'createdAt') {
  //           return new Date(user[key]).toDateString() === new Date(filters[key]).toDateString();
  //         }
  //         return String(user[key]).toLowerCase().includes(String(filters[key]).toLowerCase());
  //       });
  //     });
  //     this.setState({filteredData});
  //   }


  clearFilter = async () => {
    await this.setState({ filters: {
        firstName: '',
        email: '',
        phoneNumber: ''
      },
      pageSize : 10,
      currentPageNumber : 1,
      totalResponseData : '',
      totalResponsePages : ''
    });
    this.fetchList();
  }

  async handleExport(){
  //   exportToExcel(this.state.filteredData, 'RegisteredOnly');
  const {pageSize, currentPageNumber, filters} = this.state; 
    this.setState({ loading: true });
    try {
        const recievedData = await AuthAPI.usersList('All', currentPageNumber, filters, 'registeredOnly');
        // const combinedData = [...rewardsData, ...this.state.filteredData];
        exportToExcel(recievedData.data, 'RegisteredOnly');
    } catch (error) {
        console.error("Error exporting data:", error);
    }finally {
        this.setState({ loading: false });
    }
  };

  handlePageSizeChange = (newValue : any) => { this.setState({ pageSize: newValue, currentPageNumber: 1 }); };
  handlePageNumberChange = (newValue : any) => { this.setState({ currentPageNumber: newValue }); };

  componentDidUpdate(prevProps: {}, prevState: State) {
      if (prevState.pageSize !== this.state.pageSize || prevState.currentPageNumber !== this.state.currentPageNumber) { this.fetchList(); }
  };

  render() {
    // const { filteredData, filters } = this.state;
    const { filteredData, filters, pageSize, currentPageNumber, totalResponseData, totalResponsePages } = this.state;
    return (
        <>
        <GridContainer>
            <div className='w-100 mb-3'>
              <CardHeader color="primary">
                <div className="d-flex align-items-center justify-content-between">
                <h4>Registered Only Panelists</h4>
                </div>
              </CardHeader>
            </div>
        </GridContainer>

          <div className="jumbotron bg-white p-3 border shadow-sm">
            <div className='mb-3'>Filter</div>

            <form>
              <div className="row">
                <div className="col">
                  <label>Email</label>
                  <input type="text"
                         className="form-control"
                         placeholder="Enter..."
                         name="email"
                         value={filters.email}
                         onChange={this.handleFilterChange}
                  />
                </div>
                <div className="col">
                  <label>Phone Number</label>
                  <input type="email"
                         className="form-control"
                         placeholder="Enter..."
                         name="phoneNumber"
                         value={filters.phoneNumber}
                         onChange={this.handleFilterChange}
                  />
                </div>
              </div>
            </form>

            <div className="jumbotron bg-white p-1 mt-2 shadow-sm">
              <button type="button" className="btn btn-success" onClick={() => this.applyFilters()}>Filter Panelists</button>
              <button type="button" className="btn btn-info ml-1" onClick={() => this.handleExport()}>Export</button>
              <button type="button" className="btn btn-danger ml-1" onClick={() => this.clearFilter()}>Clear Filter</button>
            </div>


          </div>

      <div className="jumbotron bg-white p-3 border shadow-sm">
        <Alert variant="danger" show={this.state.status === PageStatus.Error}>
          {this.state.error}
        </Alert>

        <Show when={this.state.status === PageStatus.Loading}>
          <div className="d-flex justify-content-center w-100 p-5">
            <Spinner animation="border" variant="primary" />
          </div>
        </Show>

        <Show when={this.state.status === PageStatus.Loaded}>

          <Show when={!this.state.filteredData.length}>
            <Alert variant="info" show={!this.state.filteredData.length}>
              No data is available
            </Alert>
          </Show>

          <Show when={!!this.state.filteredData.length}>
          <div className='mb-2'>  
                <ShowDataAccording2PageSize 
                  currentPageSize={pageSize} 
                  currentPage={currentPageNumber}
                  sendPageSize={this.handlePageSizeChange}
                  showTotalData={totalResponseData} 
                  sendLastPage={totalResponsePages}
                />
            </div>
            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '9999' }}>
                {this.state.loading && (
                    <img style={{ height: "415px", }}
                        src="../loadernew.gif"
                        alt="Loading..."
                    />
                )}
            </div>
          <Table responsive size="sm" bordered>
            <thead>
              <tr>
                <th>S.No</th>
                <th>User Id</th>
                <th>Email</th>
                <th>Phone Number</th>
                <th>CreatedAt</th>
              </tr>
            </thead>

            <tbody>
              {
                this.state.filteredData.map((info, index) => (
                  <tr key={info.id}>
                    {/* <td>{this.state.filteredData.length - index}</td> */}
                    <td>{((pageSize)*(currentPageNumber-1))+(index + 1)}</td>
                    <td>{info.id}</td>
                    <td>
                      <span
                        aria-label="button"
                        role="button"
                        tabIndex={0}
                        className="text-primary"
                        onKeyPress={() => null}
                        onClick={() => {
                          this.setState({
                            formType: MODAL_TYPES.NONE,
                            id: info.id,
                          });
                        }}
                        dangerouslySetInnerHTML={{
                          __html: info.email || '-',
                        }}
                      />
                    </td>
                    <td>{info.phoneNumber}</td>
                    <td>{moment(info.createdAt).format('MM/DD/YYYY HH:mm A')}</td>
                  </tr>
                ))
            }
            </tbody>

          </Table>
          <div>
            <PaginationNumbering 
              currentPage={currentPageNumber} 
              returnPagenumber={this.handlePageNumberChange}
              showTotalData={totalResponseData} 
              sendLastPage={totalResponsePages}
            />
          </div>
        </Show>
        </Show>
      </div>
        </>
    );
  }
}
