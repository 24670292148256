import React, { Component } from 'react';
import { PageStatus,SeoPageTitle } from '../../enums';
import {AuthAPI} from '../../API'
import Language from "../../Languages/Login/content.json";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {authRegistration} from "./auth.actions";
import { Assets } from 'enums';
import { checkNumberValidation, checkStrongPassword } from "../../Helper/Validations";
import { changePasswordDict } from 'Languages/ContactsTranslations';



class ResetPasswordForm extends Component {
    constructor(props) {
        super(props);
        (this.state = {
            error: null,
            status: PageStatus.None,
            input: {"password" : null, "confirm_password": null},
            errors: {},
            token: null,
            passwordactive: false,
            passwordconfirmactive:false,
            metaData: null,
            pageContent: this.props.language === 'hi' ? Language.SignupHindi : Language.SignupEnglish,
            isPasswordVisible: false,
            isRepeatPasswordVisible: false,
            isValidPassword: true,
            notValidPasswordText: "",
            isValidConfirmPassword: true,
            notValidConfirmPasswordText: "",
            isPasswordMatched : false,
        });
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const token = this.props.match.params.token
        this.setState({token:token})
    }


    handleChange(event) {
        let input = this.state.input;
        input[event.target.name] = event.target.value;
        this.setState({input, isPasswordMatched : (input["confirm_password"] == input["password"] ? true : false),})
    }

    validate(){
        let input = this.state.input;
        let errors = {};
        let isValid = true;
        if (!input["password"]) {
            isValid = false;
            errors["password"] = "Please enter your password.";
        }
        if (!input["confirm_password"]) {
            isValid = false;
            errors["confirm_password"] = "Please enter your confirm password.";
        }

        if (typeof input["password"] !== "undefined" && typeof input["confirm_password"] !== "undefined") {
            if (input["password"] != input["confirm_password"]) {
                isValid = false;
                errors["password"] = "Passwords don't match.";
            }
        }

        this.setState({
            errors: errors
        });

        return isValid;
    }

    isPasswordStrong(ele, type){ 
        let errors = {};
        errors["confirm_password"] = "";
        errors["password"] = "";
        this.setState({
            errors: errors
        });
        if(ele.target.value != ""){
          let passwordStorg = checkStrongPassword(ele);
          let isHindiTrue = this.props.language === 'hi' ? true : false;
          let myText = "";
          passwordStorg.lengthCheck == true ? "" : myText = `${type == 'password' ? ( isHindiTrue ? "पासवर्ड" :'Password') : (isHindiTrue ? "कन्फर्म पासवर्ड":'Confirm password')} ${isHindiTrue ? " की लंबाई कम से कम 8 अक्षर की होनी चाहिए" : " length should be atleast of 8 character"}`; 
          passwordStorg.capitalLatterCheck == true ? "" : myText = `${type == 'password' ? ( isHindiTrue ? "पासवर्ड" :'Password') : (isHindiTrue ? "कन्फर्म पासवर्ड":'Confirm password')} ${isHindiTrue ? " में एक बड़ा अक्षर होना चाहिए" : " should contain one capital letter"}`; 
          passwordStorg.smallLatterCheck == true ? "" : myText = `${type == 'password' ? ( isHindiTrue ? "पासवर्ड" :'Password') : (isHindiTrue ? "कन्फर्म पासवर्ड":'Confirm password')} ${isHindiTrue ? " में एक छोटा अक्षर होना चाहिए" : " should contain one small letter"}`; 
          passwordStorg.numericValCheck == true ? "" : myText = `${type == 'password' ? ( isHindiTrue ? "पासवर्ड" :'Password') : (isHindiTrue ? "कन्फर्म पासवर्ड":'Confirm password')} ${isHindiTrue ? " में एक संख्यात्मक मान होना चाहिए" : " should contain one numeric value"}`; 
          passwordStorg.specialCharCheck == true ? "" : myText = `${type == 'password' ? ( isHindiTrue ? "पासवर्ड" :'Password') : (isHindiTrue ? "कन्फर्म पासवर्ड":'Confirm password')} ${isHindiTrue ? " में एक विशेष प्रतीक होना चाहिए" : " should contain one special character"}`; 
          if(passwordStorg.capitalLatterCheck == true && passwordStorg.smallLatterCheck == true && passwordStorg.numericValCheck == true && passwordStorg.specialCharCheck == true && passwordStorg.lengthCheck == true && type == "password"){
            this.setState({ isValidPassword: true, notValidPasswordText: "", isButtonDisabled: false, })
          }else if(passwordStorg.capitalLatterCheck == true && passwordStorg.smallLatterCheck == true && passwordStorg.numericValCheck == true && passwordStorg.specialCharCheck == true && passwordStorg.lengthCheck == true && type == "confirmpassword"){
            this.setState({ isValidConfirmPassword: true, notValidConfirmPasswordText: "", isButtonDisabled: false, })
          }else{ 
            if(type == "password"){
              this.setState({ isValidPassword: false, notValidPasswordText: myText, isButtonDisabled: true, })
            }else{
              this.setState({ isValidConfirmPassword: false, notValidConfirmPasswordText: myText, isButtonDisabled: true, })
            }
          }
        }else{ 
          if(type == "password"){
            this.setState({ isValidPassword: true, notValidPasswordText: "", isButtonDisabled: false, })
          }else{
            this.setState({ isValidConfirmPassword: true, notValidConfirmPasswordText: "", isButtonDisabled: false, })
          }
        }
    }

    handleSubmit(event) {
        event.preventDefault();
        if(this.validate()) {
            return Promise.resolve()
                .then(() => this.setState({status: PageStatus.Submitting}))
                .then(() => AuthAPI.UpdateNewPassword(this.state.token, this.state.input.password))
                .then((res) => {
                    if (res === 'Not found.') {
                        alert('Invalid Token');
                        this.setState({status: PageStatus.Submitted})
                    }
                    alert('password updated  successfully ');
                    this.setState({status: PageStatus.Submitted}, () => {
                        let input = {};
                        input["password"] = "";
                        input["confirm_password"] = "";
                        this.setState({input: input});
                        this.props.history.push({
                            pathname: '/auth/login'
                        })
                    })
                })
                .catch((error) => {
                    this.setState({status: PageStatus.Error, error: error.message});
                });
        }
    }

    render() {
        const lang = this.props.language ?? 'en';
        const { isPasswordMatched, isValidPassword, notValidPasswordText, isValidConfirmPassword, notValidConfirmPasswordText, } = this.state;
        return (
            <>
                <div>
                    <section className='register_page'>
                        <div className='register_left'>
                            <img
                                src={Assets.Logo2}
                                alt='register-left'
                                className='img-fluid '
                                height={50}
                            />
                        </div>
                        <div className='register_right'>
                            <h2 className='register_heading'> {this.props.language === 'hi' ? 'पासवर्ड रीसेट' : 'Reset Password'}</h2>

                            <div className='or_border'>
                                <p className='or_text'></p>
                            </div>
                            <form onSubmit={this.handleSubmit} >
                                {/* <div className={ this.state.passwordactive == true || this.state.input.password!=='' ? 'input_group input_group_active': 'input_group'} style={{ position: 'relative',}}> */}
                                <div className='mb-3 mt-3' style={{ position: 'relative',}}>
                                    {/* <label for='' className='custom_label'>
                                        {this.props.language === 'hi' ? 'पासवर्ड' : 'Password'}:
                                    </label> */}
                                    <input
                                        // type="password"
                                        type={this.state.isPasswordVisible ? 'text' : 'password'}
                                        name="password"
                                        value={this.state.input.password}
                                        onChange={this.handleChange}
                                        onInput={(e)=>this.isPasswordStrong(e, 'password')}
                                        // className='custom_input_field'
                                        className='form-control'
                                        placeholder={this.props.language === 'hi' ? 'पासवर्ड' : 'Password'}
                                        onClick={() => this.setState({ passwordactive: !this.state.passwordactive })} />
                                    {/* <div className="text-danger">{this.state.errors.password}</div> */}
                                    <button
                                        type="button"
                                        style={{ position: 'absolute', top: '50%', backgroundColor:"white", right: '10px', transform: 'translateY(-50%)' }}
                                        onClick={() => this.setState({ isPasswordVisible: !this.state.isPasswordVisible })}
                                    >
                                        <i className={!this.state.isPasswordVisible ? "fa fa-eye-slash bg-none" : "fa fa-eye"}></i>
                                    </button>
                                </div>
                                {isValidPassword ? "" : 
                                    <div className="text-red mt-2" style={{fontSize:`${window.matchMedia("(max-width: 768px)").matches ? "18px" : "16px"}` }}>
                                    {notValidPasswordText}
                                    </div>
                                }
                                {/* <div className={ this.state.passwordconfirmactive == true || this.state.input.confirm_password!==''? 'input_group input_group_active': 'input_group'} style={{ position: 'relative',}}> */}
                                <div className='mb-3 mt-3' style={{ position: 'relative',}}>
                                    {/* <label for='' className='custom_label'  >
                                        {this.props.language === 'hi' ? 'पासवर्ड की पुष्टि कीजिये' : 'Confirm Password'}:
                                    </label> */}
                                    <input
                                        // type="password"isRepeatPasswordVisible
                                        type={this.state.isRepeatPasswordVisible ? 'text' : 'password'}
                                        name="confirm_password"
                                        value={this.state.input.confirm_password}
                                        onChange={this.handleChange}
                                        onInput={(e)=>this.isPasswordStrong(e, 'confirmpassword')}
                                        // className='custom_input_field'
                                        className='form-control'
                                        placeholder={this.props.language === 'hi' ? 'पासवर्ड की पुष्टि कीजिये' : 'Confirm Password'}
                                        onClick={() => this.setState({passwordconfirmactive: !this.state.passwordconfirmactive})}
                                    />
                                    {/* <div className="text-danger">{this.state.errors.confirm_password}</div> */}
                                    <button
                                        type="button"
                                        style={{ position: 'absolute', top: '50%', backgroundColor:"white", right: '10px', transform: 'translateY(-50%)' }}
                                        onClick={() => this.setState({ isRepeatPasswordVisible: !this.state.isRepeatPasswordVisible })}
                                    >
                                        <i className={!this.state.isRepeatPasswordVisible ? "fa fa-eye-slash bg-none" : "fa fa-eye"}></i>
                                    </button>
                                </div>
                                    {isPasswordMatched && isValidPassword && isValidConfirmPassword  ? 
                                        <div className="alert alert-success">{changePasswordDict[lang]["Passwords Matched"] || "Passwords matched!"}</div> : ""
                                    }
                                    {!isPasswordMatched && this.state.input.password && this.state.input.confirm_password && isValidPassword && isValidConfirmPassword ?
                                        <div className="alert alert-danger">{changePasswordDict[lang]["Not Matched"] || "Not Matched"}</div> : ""}
                                    {isValidConfirmPassword ? "" : 
                                    <div className="text-red mt-2" style={{fontSize:`${window.matchMedia("(max-width: 768px)").matches ? "18px" : "16px"}` }}>
                                        {notValidConfirmPasswordText}
                                    </div>}
                                    <div className="text-danger">{this.state.errors.password}</div>
                                    <div className="text-danger">{this.state.errors.confirm_password}</div>

                                <button  type="submit" className='create_acc_btn rippleeffect-btn' disabled={!isPasswordMatched || !isValidPassword || !isValidConfirmPassword} >
                                    {this.props.language === 'hi' ? 'जमा करना' : 'Submit'}
                                </button>

                            </form>
                        </div>
                    </section>
                </div>
            </>

        )
    }
}



const mapStateToProps = (state) => {
    return {
        isAuth: state.adminUser.adminUser.isAuthenticated,
        token: state.adminUser.adminUser.token,
        language: state.adminUser.adminUser.language,
        isLoading: state.adminUser.adminUser.loading,
        error: state.adminUser.adminUser.error,
    };
};

const ResetPasswordFormWithState = withRouter(connect(
    mapStateToProps, { authRegistration },
)(ResetPasswordForm));

export {
    ResetPasswordForm,
    ResetPasswordFormWithState,
};
